import { defaultFor } from "common";
import { Properties } from "common/types/records";
import {
  getUiFormProperties,
  propertiesToEventProperties,
} from "common/record/form/content/related/event/functions";
import { CheckAvailability } from "common/record/form/content/related/event/check-availability";
import {
  RelatedValue,
  CustomConfig,
  CustomConfigSettings,
} from "common/record/form/content/related/types";

export const eventRef: CustomConfig<RelatedValue> = ({
  context,
  relatedEntity,
  isTemplate,
  value: { record, related },
}): CustomConfigSettings<RelatedValue> => {
  const defaultForm: Properties = relatedEntity.ownerFkColumn
    ? { [relatedEntity.ownerFkColumn]: record.properties.id }
    : undefined;
  const relatedEntityName = relatedEntity.name.toLowerCase();
  return {
    defaultForm,
    layoutMapper: undefined,
    custom: (properties = defaultFor<Properties>()) =>
      isTemplate ? undefined : (
        <CheckAvailability
          context={context}
          entity={relatedEntity}
          eventProps={propertiesToEventProperties(properties)}
          uiFormProperties={getUiFormProperties(related, relatedEntityName)}
        />
      ),
    customOnChange: undefined,
  };
};
