import { Component } from "react";
import { defaultFor, noOp } from "common";
import { searchApi } from "common/api/search";
import {
  addDateDuration,
  getDifferenceInHours,
} from "common/date-time/calculators";
import { canDo } from "common/entities";
import { getFkId } from "common/functions/foreign-key";
import { getFormByIdOrEntity } from "common/functions/forms";
import { getContactsQuery } from "common/functions/query";
import { mergeChain } from "common/merge";
import { areChargesReadOnly } from "common/record/disabled-related-entity";
import { StandardRelated } from "common/record/form/content/related/standard-related";
import { PropTypes } from "common/record/form/content/related/table-with-form/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { ValueProps } from "common/with-value-for";
import { getPartialForm } from "../functions";
import {
  getFetchRateValueQuery,
  getRateEntity,
  shouldComputeQty,
  shouldComputeRangeTo,
  updateLaborChargeContext,
} from "./functions";

interface StateType {
  rateTypeIds: string[];
}

type Props = PropTypes & ValueProps<RelatedValue>;

export class LaborCharge extends Component<Props, StateType> {
  static readonly displayName = "LaborCharge";

  state: StateType = {
    rateTypeIds: undefined,
  };

  componentDidMount() {
    const { context, entity, value, parentEntity, displayTypes } = this.props;
    const properties = value?.record?.properties || defaultFor<Properties>();

    const isReadOnly = areChargesReadOnly(
      context.entities[parentEntity.name],
      entity,
      properties,
      displayTypes,
    );

    if (!isReadOnly) {
      const partialForm = getPartialForm(value, entity.name);
      // partial form must be checked first for when you fill, navigate away and come back
      this.fetchRateTypesForContact(
        partialForm?.targetId ??
          this.getFormDefaults(partialForm?.formId)?.targetId,
      );
    }
  }

  getFormDefaults = (formId: number) => {
    const { context, entity } = this.props;

    const rateEntityForm = getFormByIdOrEntity(
      context.forms,
      entity.name,
      formId,
    );

    return rateEntityForm?.settings.defaults;
  };

  getContactId = (targetId: FkValue) => {
    const { context, entity } = this.props;

    if (targetId !== "{user}")
      return CancellablePromise.resolve(getFkId(targetId));

    const contactsEntity = context.entities[entity.arguments.targetEntity];
    const contactQuery = getContactsQuery(contactsEntity, context.userName);

    return searchApi(context.apiCall)
      .runQuery(contactQuery)
      .then((records: Properties[]) => records?.[0]?.id);
  };

  fetchRateTypesForContact = (targetId: FkValue) => {
    const { context, entity } = this.props;

    this.getContactId(targetId).then((contactId) => {
      this.setState({ rateTypeIds: undefined });
      if (!contactId) return;

      const contactsEntityName = entity.arguments.targetEntity;

      searchApi(context.apiCall)
        .getRateTypes(contactsEntityName, contactId)
        .then((data: Properties[]) => {
          this.setState({
            rateTypeIds: data.map((r) => r.id),
          });
        })
        .catch(noOp);
    });
  };

  fetchUnitCostForContact = (
    targetId: ForeignKey,
    rateTypeId: ForeignKey,
  ): CancellablePromise<number> => {
    const { context, entity } = this.props;
    const rateEntity = getRateEntity(context.entities, entity);

    if (!rateEntity || !canDo(entity, "ViewLaborRates"))
      return CancellablePromise.resolve(undefined);

    const query = getFetchRateValueQuery(
      rateEntity.name,
      targetId.id,
      context.userName, // when target is an expression like {user}
      rateTypeId.id,
    );

    return searchApi(context.apiCall)
      .runQuery(query)
      .then((data: Array<{ value: number }>) => {
        return data?.[0].value;
      })
      .catch(() => {
        return undefined;
      });
  };

  onChange = (newValue: RelatedValue) => {
    const { entity, value, onChange } = this.props;
    const newForm = getPartialForm(newValue, entity.name);
    const oldForm = getPartialForm(value, entity.name);

    const contactChanged = oldForm?.targetId !== newForm?.targetId;
    const rateTypeChanged = oldForm?.rateTypeId !== newForm?.rateTypeId;

    // if contact changed, we need to check its rate types. no need to wait
    if (contactChanged)
      this.fetchRateTypesForContact(
        newForm?.targetId ?? this.getFormDefaults(newForm?.formId)?.targetId,
      );

    const durationProps = shouldComputeQty(oldForm, newForm)
      ? { quantity: getDifferenceInHours(newForm.rangeFrom, newForm.rangeTo) }
      : shouldComputeRangeTo(oldForm, newForm)
        ? {
            rangeTo: addDateDuration(
              newForm.rangeFrom,
              // quantity float stores hours and will be rounded down when adding
              newForm.quantity * 60,
              "minutes",
            ),
          }
        : {};

    const nextForm: Properties = {
      ...newForm,
      ...durationProps,
      // resets rateType when contact changed. however when contact is {user},
      // when you change rateType, you will get a contact update too: {user} -> id.
      // this is because forms & defaults are resolved in children and we find them out only on updates...
      rateTypeId:
        contactChanged && !rateTypeChanged ? undefined : newForm?.rateTypeId,
      unitCost:
        contactChanged || rateTypeChanged ? undefined : newForm?.unitCost,
    };

    const mergePartial = (partial: Properties) => {
      const updates = newValue?.related?.form?.[entity.name].length;
      onChange(
        mergeChain(value)
          .prop("related")
          .set("form", newValue?.related?.form)
          .set("isDirty", !!updates)
          .prop("partialForm")
          .set(entity.name, partial)
          .output(),
      );
    };

    if (nextForm.rateTypeId && rateTypeChanged) {
      this.fetchUnitCostForContact(nextForm.targetId, nextForm.rateTypeId).then(
        (unitCost) => {
          mergePartial({ ...nextForm, unitCost });
        },
      );
    } else {
      mergePartial(nextForm);
    }
  };

  render() {
    const { value, entity, context, displayTypes, parentEntity } = this.props;
    const { rateTypeIds } = this.state;

    const properties = value?.record?.properties;

    const readOnlyEntity = areChargesReadOnly(
      context.entities[parentEntity.name],
      entity,
      properties || defaultFor<Properties>(),
      displayTypes,
    );

    // TODO every setState + render here `context` will be different and
    // CreateRelatedFormWithDependencies is re-fetching default dependencies. good luck with that.

    const updatedContext = updateLaborChargeContext(
      context,
      entity,
      rateTypeIds,
    );

    const { entities } = updatedContext;
    const updatedEntity = entities[entity.name];

    return (
      <StandardRelated
        {...this.props}
        displayTypes={readOnlyEntity ? ["table"] : undefined}
        onChange={this.onChange}
        context={updatedContext}
        entity={updatedEntity}
      />
    );
  }
}
