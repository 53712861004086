import { withValue, WithValue } from "common/with-value";
import { email } from "common/validate";
import { InputWithSubmit, OnSubmit } from "./input-with-submit";

export interface PropTypes {
  onSubmit?: OnSubmit;
  disabled?: boolean;
  validate?: boolean;
  label?: string;
}

const EmailComp = ({
  label,
  disabled,
  validate,
  onSubmit,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<string>) => (
  <InputWithSubmit
    className="x-email"
    label={label}
    type="email"
    value={value}
    hasError={validate && !email(value)}
    disabled={disabled}
    submitOnBlur={true}
    onSubmit={onSubmit}
    onChange={onChangeSetValue}
  />
);

export const Email = withValue<string, PropTypes>(EmailComp, "Email");
