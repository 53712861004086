import * as R from "ramda";
import { getDifferenceInSeconds } from "common/date-time/calculators";
import { transferSitedToLocal } from "common/date-time/helpers/scheduler-bryntum";
import {
  isAssignmentEvent,
  isNotWorkingEvent,
  isRelatedEvent,
  isUnpublishedEvent,
} from "common/functions/scheduler";
import type { Record } from "common/types/records";
import type {
  AssignmentEvent,
  ContactEvents,
  RelatedEvent,
  SchedulerEvent,
} from "common/types/scheduler";
import {
  DEFAULT_EVENT_COLOR,
  DEFAULT_PERSONAL_EVENT_COLOR,
  UNPUBLISHED_EVENT_COLOR_FACTOR,
} from "x/scheduler2/consts";
import type { EventModel } from "x/scheduler2/types";
import { getColorByRuleId } from "common/api/color-rule";
import type { Context } from "common/types/context";
import {
  getColorShade,
  getContrastyTextColor,
  rgbToHexString,
} from "common/functions/colors";
import { COLOR_BLACK } from "common/types/colors";

const getAssignmentTitle = (event: AssignmentEvent) => event.workOrderId.title;

const getRelatedTitle = (_event: RelatedEvent) => _("Personal Event");

const eventTitle = (event: SchedulerEvent) =>
  isAssignmentEvent(event)
    ? getAssignmentTitle(event)
    : isRelatedEvent(event)
      ? getRelatedTitle(event)
      : event.type;

export const mapEvent = (
  context: Context,
  resourceId: string,
  event: SchedulerEvent,
): EventModel => {
  const { id, rangeFrom, rangeTo, entityName, entityColorRuleId } = event;

  const isPersonalEvent = isRelatedEvent(event);
  const isUnpublished = isUnpublishedEvent(event);

  const ruleColor = getColorByRuleId(
    context.entities[entityName].colorRules,
    entityColorRuleId,
  );

  const eventColor =
    ruleColor ??
    (isPersonalEvent ? DEFAULT_PERSONAL_EVENT_COLOR : DEFAULT_EVENT_COLOR);

  const backgroundColor = isUnpublished
    ? getColorShade(eventColor, UNPUBLISHED_EVENT_COLOR_FACTOR)
    : eventColor;

  const unPublishedEventClass = isUnpublished
    ? "x-unpublished-event qa-unpublished-event"
    : "";
  const eventTextColorClass =
    getContrastyTextColor(backgroundColor) === COLOR_BLACK
      ? "x-event-text-black"
      : "x-event-text-white";

  return {
    id: id,
    resourceId,
    startDate: transferSitedToLocal(rangeFrom),
    endDate: transferSitedToLocal(rangeTo),
    draggable: !isPersonalEvent,
    resizable: !isPersonalEvent,
    name: eventTitle(event),
    eventColor: rgbToHexString(backgroundColor) as any,
    eventData: event,
    cls: [eventTextColorClass, unPublishedEventClass],
  };
};
export const getResourcesEvents = (
  context: Context,
  records: ReadonlyArray<ContactEvents>,
): EventModel[] =>
  records.reduce(
    (acc: EventModel[], record: ContactEvents) =>
      acc.concat(
        record.events
          .filter((event) => !isNotWorkingEvent(event))
          .map((event) => mapEvent(context, record.properties.id, event)),
      ),
    [],
  );

export const getSortedAssignments = (assignments: Record[] = []) => {
  const diff = (assignment1: Record, assignment2: Record) => {
    const createdOn1 = assignment1?.properties?.createdOn;
    const createdOn2 = assignment2?.properties?.createdOn;
    return getDifferenceInSeconds(createdOn1, createdOn2);
  };
  return R.sort(diff, assignments);
};
