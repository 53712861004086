import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { Culture, translateCulture } from "common/culture/supported-cultures";

const COUNTRY_CODES_EXCEPTIONS: { [key: string]: string } = {
  sr: "rs", // Serbian,
  et: "ee", // Estonian,
  sl: "si", // Slovenia,
  nb: "no", // Norway,
  da: "dk", // Denmark,
  "zh-TW": "cn", // China Traditional
};

const getCountryCodeFromLanguageCode = (culture: string) =>
  COUNTRY_CODES_EXCEPTIONS[culture] || culture?.split("-")?.[1] || culture;

export const getFlag = (culture: Culture) => {
  const flag = getCountryCodeFromLanguageCode(culture);
  return flag?.toLocaleLowerCase() ?? "";
};

export const formatCulture = (
  culture: LabelledOptionOrGroup<Culture>,
  meta: FormatMeta<Culture>,
) => {
  if (isGroupedOption(culture)) {
    return (
      <ReactHighlightWords text={culture.label} search={meta?.inputValue} />
    );
  }

  const cultureLabel = translateCulture(culture?.value) ?? _("Unknown");
  return (
    <div className="x-culture">
      <img
        src="/images/blank.gif"
        className={`flag flag-${getFlag(culture?.value)}`}
        alt={cultureLabel}
      />
      <span>
        <ReactHighlightWords text={culture?.label} search={meta?.inputValue} />
      </span>
    </div>
  );
};
