import * as R from "ramda";
import { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toKebabCase } from "common";
import { getWeekdayName } from "common/date-time/common";
import type { UTCDateRange } from "common/date-time/types";
import type { Entity } from "common/entities/types";
import type { PaginationValue, QueryForEntity } from "common/query/types";
import type { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { LoadingIcon } from "common/widgets/loading-icon";
import { InjectedProps, toggleable } from "common/widgets/toggleable";
import {
  getScrollHeight,
  hasWorkOrderById,
  removeWorkOrderById,
} from "x/scheduler2/planner/unassigned-work/functions";
import {
  getTotalCount,
  getWorkOrders,
} from "x/scheduler2/planner/unassigned-work/functions/query";
import { UnassignedWorkTile } from "x/scheduler2/planner/unassigned-work/tile";
import type {
  OnWorkOrderViewClickFn,
  WorkOrder,
} from "x/scheduler2/planner/unassigned-work/types";
import type { EntityQueries } from "x/scheduler2/types";

interface PropTypes {
  context: Context;
  label?: string;
  isDraggable: boolean;
  dateRange: UTCDateRange;
  customQueries: EntityQueries;
  searchQuery: QueryForEntity;
  searchValue: string;
  onWorkOrderViewClick: OnWorkOrderViewClickFn;
  workOrderEntity: Entity;
  assignedWorkOrderId: string;
  additionalInfo?: string;
  lastRefresh: number;
  parentPanelHeight: number;
}

interface StateType {
  /**
   * Total count of records with search applied
   */
  resultsCount: number;
  /**
   * Total count of records without search applied
   */
  totalCount: number;
  totalPages: number;
  nextPage: number;
  workOrders: WorkOrder[];
}

type Props = PropTypes & InjectedProps;

export class DayGroup extends Component<Props, StateType> {
  state: StateType = {
    resultsCount: 0,
    totalCount: 0,
    totalPages: 0,
    nextPage: undefined,
    workOrders: [],
  };

  fetchDataRequest: CancellablePromise<unknown>;

  componentDidMount() {
    this.loadInitialWorkOrders();
  }

  componentWillUnmount() {
    if (this.isDraggable(this.props)) this.cleanDraggable();
    this.fetchDataRequest?.cancel();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      context,
      lastRefresh,
      dateRange,
      customQueries,
      searchQuery,
      workOrderEntity,
      assignedWorkOrderId,
    } = this.props;
    const { workOrders } = this.state;
    const prevSite = prevProps.context.site;
    const { site } = context;

    if (
      prevProps.lastRefresh !== lastRefresh ||
      prevSite.name !== site.name ||
      !R.equals(prevProps.dateRange, dateRange) ||
      !R.equals(prevProps.customQueries, customQueries) ||
      !R.equals(prevProps.searchQuery, searchQuery) ||
      prevProps.workOrderEntity?.name !== workOrderEntity?.name
    ) {
      return this.loadInitialWorkOrders();
    }

    if (
      prevProps.assignedWorkOrderId !== assignedWorkOrderId &&
      hasWorkOrderById(workOrders, assignedWorkOrderId)
    ) {
      return this.removeAssignedWorkOrder(assignedWorkOrderId);
    }

    const wasDraggable = this.isDraggable(prevProps);
    const isDraggable = this.isDraggable(this.props);
    if (!wasDraggable && isDraggable) this.applyDraggable();
    else if (wasDraggable && !isDraggable) this.cleanDraggable();
  }

  getWorkOrdersAndCount = (page: number, perPage?: number) => {
    const { context, workOrderEntity, dateRange, customQueries, searchQuery } =
      this.props;
    const customQuery = customQueries?.[workOrderEntity?.name];

    this.fetchDataRequest = CancellablePromise.all([
      getWorkOrders(
        context,
        workOrderEntity,
        dateRange,
        customQuery,
        searchQuery,
        page,
        perPage,
      ),
      searchQuery || customQuery?.query?.filter
        ? getTotalCount(context, workOrderEntity, dateRange, customQuery)
        : CancellablePromise.resolve(),
    ]);

    return this.fetchDataRequest;
  };

  /**
   * Removes assigned WO from the `workOrders` state prop and top up the list
   *
   * One out - one in. We have to get the last WO in the place of assigned one
   * otherwise we are losing the first WO from the next page user will load.
   */
  removeAssignedWorkOrder = (assignedWorkOrderId: string) => {
    const { workOrders, resultsCount, totalCount } = this.state;
    const newWorkOrders = removeWorkOrderById(workOrders, assignedWorkOrderId);

    if (this.hasMore()) {
      this.getWorkOrdersAndCount(workOrders.length - 1, 1).then(
        ([paginationValue, count]) => {
          const updatedResultsCount =
            paginationValue.fullCount ?? resultsCount - 1;
          this.setState(
            {
              workOrders: newWorkOrders.concat(paginationValue.data ?? []),
              resultsCount: updatedResultsCount,
              totalCount: count ?? updatedResultsCount,
            },
            this.applyDraggableIfEnabled,
          );
        },
      );
    } else {
      this.setState({
        workOrders: newWorkOrders,
        resultsCount: resultsCount - 1,
        totalCount: totalCount - 1,
      });
    }
  };

  /**
   * Loads the first page of WOs clearing previously loaded data
   */
  loadInitialWorkOrders = () => {
    this.getWorkOrdersAndCount(0).then(([paginationValue, count]) => {
      const resultsCount = paginationValue.fullCount ?? 0;
      this.setState(
        {
          workOrders: paginationValue.data ?? [],
          resultsCount,
          totalPages: paginationValue.totalPages ?? 0,
          nextPage: paginationValue.next ?? undefined,
          totalCount: count ?? resultsCount,
        },
        this.applyDraggableIfEnabled,
      );
    });
  };

  /**
   * Loads next page of WOs adding them to `workOrder` state prop
   */
  loadNextWorkOrders = () => {
    const { resultsCount, totalPages, nextPage, workOrders } = this.state;

    if (nextPage === undefined) return;

    this.getWorkOrdersAndCount(nextPage).then(([paginationValue, count]) => {
      const updatedResultsCount = paginationValue.fullCount ?? resultsCount;
      this.setState(
        {
          workOrders: workOrders.concat(paginationValue.data ?? []),
          resultsCount: updatedResultsCount,
          totalPages: paginationValue.totalPages ?? totalPages,
          nextPage: this.getNextPage(paginationValue),
          totalCount: count ?? updatedResultsCount,
        },
        this.applyDraggableIfEnabled,
      );
    });
  };

  /**
   * Fix for back-end work returning wrong `next` for the last page
   */
  getNextPage = (paginationValue: PaginationValue<WorkOrder>) => {
    return paginationValue.next === this.state.nextPage
      ? undefined
      : paginationValue.next;
  };

  isDraggable = (props: Props) => {
    return (!props.toggleFn || props.toggleOpened) && props.isDraggable;
  };

  cleanDraggable = () => {
    //TODO Update this function when the drag and drop is implemented
  };

  applyDraggableIfEnabled = () => {
    if (this.isDraggable(this.props)) this.applyDraggable();
  };

  applyDraggable = () => {
    //TODO Update this function when the drag and drop is implemented
  };

  getContainerClassName = () => {
    return `x-unassigned-group-${toKebabCase(this.getLabel())}`;
  };

  getLabel = () => {
    const { dateRange, label } = this.props;
    return label ?? getWeekdayName(dateRange.from);
  };

  hasMore = () => {
    return this.state.nextPage !== undefined;
  };

  render() {
    const {
      onWorkOrderViewClick,
      toggleOpened,
      toggleFn,
      additionalInfo,
      isDraggable,
      context,
      customQueries,
      searchQuery,
      parentPanelHeight,
      searchValue,
      workOrderEntity,
    } = this.props;
    const { resultsCount, totalCount, workOrders } = this.state;

    const cursorClass = toggleFn ? "x-pointer" : "";
    const overdueClass = additionalInfo ? "x-overdue" : "";
    const iconClass = toggleOpened ? "fa-chevron-down" : "fa-chevron-up";

    const customQuery = customQueries?.[workOrderEntity.name];
    const countLabel =
      searchQuery || customQuery?.query?.filter
        ? _("{RESULTS} of {TOTAL}")
            .replace("{RESULTS}", `${resultsCount}`)
            .replace("{TOTAL}", `${totalCount}`)
        : resultsCount;

    const containerClassName = this.getContainerClassName();
    const qaContainerClassName = `qa${containerClassName.slice(1)}`;

    return (
      <div className={`${containerClassName} ${qaContainerClassName}`}>
        <div
          className={`x-work-order-day-title ${overdueClass} ${cursorClass}`}
          onClick={toggleFn}
        >
          <h2>
            <span className="x-text-uppercase">{this.getLabel()}</span>
            {` (${countLabel})`}
          </h2>
          {toggleFn && <i className={`fa ${iconClass}`} />}
        </div>
        {additionalInfo && toggleOpened && (
          <div className="x-overdue-info">{additionalInfo}</div>
        )}
        {(!toggleFn || toggleOpened) &&
          (workOrders.length ? (
            <InfiniteScroll
              next={this.loadNextWorkOrders}
              hasMore={this.hasMore()}
              loader={<LoadingIcon />}
              dataLength={workOrders.length}
              height={getScrollHeight(parentPanelHeight, resultsCount)}
            >
              {workOrders.map((wo) => (
                <UnassignedWorkTile
                  key={wo.SYSTEM_id}
                  workOrder={wo}
                  draggable={isDraggable}
                  context={context}
                  customQueries={customQueries}
                  searchValue={searchValue}
                  onClick={onWorkOrderViewClick}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <div className="x-work-order-message">{_("No planned work")}</div>
          ))}
      </div>
    );
  }
}

export const ToggleableDayGroup = toggleable({ defaultOpened: false })(
  DayGroup,
);
