import { isValid } from "date-fns";
import { format, toDate, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { FORMAT_ISO } from "common/date-time/formats";
import {
  DEFAULT_TIME_ZONE,
  getLocalTimeZone,
  getSiteTimeZone,
} from "common/date-time/global";

/*
 * This file contains internal functions for the date-time module.
 * None of the functions here should be accessed directly from the
 * regular X5 code.
 */

export const utcToSited = (date: Date, timezone?: string) =>
  isValid(date)
    ? utcToZonedTime(date, timezone ?? getSiteTimeZone())
    : undefined;

export const localToUtc = (date: Date) =>
  isValid(date) ? zonedTimeToUtc(date, getLocalTimeZone()) : undefined;

export const sitedToUtc = (date: Date, timezone?: string) =>
  isValid(date)
    ? zonedTimeToUtc(date, timezone ?? getSiteTimeZone())
    : undefined;

export const localToSited = (date: Date, timezone?: string) =>
  isValid(date) ? utcToSited(localToUtc(date), timezone) : undefined;

/**
 * Creates sited time from given string, number or Date. String is parsed,
 * a number considered unix time in milliseconds. If time zone can't be
 * determined based on an offset a default time zone is chosen.
 * @param date
 * @param timezone
 */
export const parseToSited = (
  date: string | number | Date,
  timezone?: string,
) => {
  const localDate = toDate(date, { timeZone: DEFAULT_TIME_ZONE });
  return isValid(localDate) ? localToSited(localDate, timezone) : undefined;
};

export const utcToISO = (date: Date) =>
  isValid(date) ? date.toISOString() : undefined;

export const sitedToISO = (date: Date, timezone?: string) =>
  isValid(date)
    ? format(date, FORMAT_ISO, { timeZone: timezone ?? getSiteTimeZone() })
    : undefined;

export const sitedToUtcISO = (date: Date) =>
  isValid(date) ? utcToISO(sitedToUtc(date)) : undefined;

export const getSitedNowDate = () => parseToSited(Date.now());
