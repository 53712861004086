import * as R from "ramda";
import { JSX } from "react";
import { defaultFor, toCamelCase } from "common";
import { MapWidgetPropsFn } from "common/form/types";
import { applyLookupQueryToColumn } from "common/query/entities";
import { getFilterKey } from "common/query/filter";
// eslint-disable-next-line import/no-cycle
import { TableActions } from "common/query/table/actions";
import {
  QUICK_ACTIONS,
  QuickActions,
} from "common/query/table/actions/quick-actions";
// eslint-disable-next-line import/no-cycle
import { TableCell } from "common/query/table/cell";
import { getAdditionalProps } from "common/query/table/functions";
// eslint-disable-next-line import/no-cycle
import { BasicPropTypes } from "common/query/table/header";
import { RowValue } from "common/query/table/types";
import { Secondaries, SelectField } from "common/query/types";
import { Action } from "common/record/actions/types";
import { getFormOrDefault } from "common/record/edit/functions";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { GoFn } from "common/types/url";
import { arrayToString } from "common/utils/array";
import { Checkbox } from "common/widgets/checkbox/checkbox";
import { RelatedSummaryComponent } from "common/widgets/related-summary";
import { Star } from "common/widgets/star";
import { ValueComponent } from "common/with-value-for";
import { ColumnDefinition } from "../advanced-types";

interface PropTypes extends BasicPropTypes {
  entity: string;
  actions: Action[];
  allowStar: boolean;
  allowActions: boolean;
  allowImage: boolean;
  reload: () => any;
  starred: boolean;
  withLinks: boolean;
  toggleStar: (id: string, starred: boolean) => void;
  secondaryQueries: Secondaries;
  goTo: GoFn;
  isHighlighted?: boolean;
  readOnly?: boolean;
  isEditing?: boolean;
  ignoredColumnDefinitions?: ColumnDefinition[];
  widgetsMapper?: MapWidgetPropsFn;
}

const defaultValue = defaultFor<RowValue>();

export class TableRow extends ValueComponent<RowValue, PropTypes> {
  static readonly displayName = "TableRow";

  onChangeIsSelected = (isSelected: boolean) => {
    const { value, onChange } = this.props;
    onChange({ ...value, isSelected });
  };

  onChangeCell = (valueKey: string) => {
    return (properties: Properties) => {
      this.mergeValue3("record", "properties", valueKey, properties);
    };
  };

  render() {
    const {
      context,
      entity,
      actions = [],
      allowSelect,
      allowStar,
      allowActions,
      reload,
      starred,
      toggleStar,
      withLinks,
      readOnly,
      columnDefinitions = [],
      ignoredColumnDefinitions = [],
      secondaryQueries,
      goTo,
      isHighlighted,
      hasActions,
      isEditing,
      widgetsMapper,
      value = defaultValue,
    } = this.props;
    const { record, isSelected, index } = value;

    const { entities } = context;
    const { properties = {} } = record || {};
    const { id, isDeleted, relatedSummary, formId } = properties;

    const selectTd = allowSelect ? (
      <td className="x-select-column">
        <Checkbox value={isSelected} onChange={this.onChangeIsSelected} />
      </td>
    ) : undefined;

    const widgetsMap = widgetsMapper && widgetsMapper(properties);

    const actionsToOmit = QUICK_ACTIONS.concat(
      widgetsMap?.tableActionsToOmit?.actions ?? [],
    );

    const quickActions = (
      <QuickActions actions={actions} properties={properties} index={index} />
    );

    const baseActions = id
      ? actions
      : actions.filter((action) => action.name === "Delete");
    const predefinedActions = baseActions.filter(
      (action) => !actionsToOmit.includes(action.name),
    );

    const recordActions =
      (hasActions && allowActions) || predefinedActions.length ? (
        <TableActions
          goTo={goTo}
          reload={reload}
          context={context}
          entity={entities[entity]}
          record={record}
          predefinedActions={predefinedActions}
        />
      ) : undefined;

    const star =
      hasActions && allowStar ? (
        <Star
          starred={starred}
          recordId={id}
          toggleStar={toggleStar}
          className="x-action"
        />
      ) : undefined;

    const itemCount =
      (allowActions || predefinedActions.length ? 1 : 0) +
      (allowStar ? 1 : 0) +
      (quickActions ? 1 : 0);

    const actionsColumn = hasActions ? (
      <td className={`x-actions-column x-actions-column-${itemCount}`}>
        <div className="x-actions-container">
          {recordActions}
          {star}
          {quickActions}
        </div>
      </td>
    ) : undefined;

    const cells: JSX.Element[] = columnDefinitions.map((def, i) => {
      const valueKey = R.isNil(properties[def.valueKey])
        ? toCamelCase(def.valueKey)
        : def.valueKey;

      const relatedEntityName = def.entity;
      const ignoredEntityColumn = ignoredColumnDefinitions.find(
        (c) =>
          c.entity === relatedEntityName &&
          c.column.dataType === "uniqueidentifier" &&
          !(c.item as SelectField)?.fn,
      );

      const relatedRecordId = properties[ignoredEntityColumn?.valueKey];

      const additionalProps = getAdditionalProps(
        entities[entity],
        def.column,
        properties,
        context,
      );

      const layoutForm = getFormOrDefault(context, relatedEntityName, formId);
      const column = applyLookupQueryToColumn(
        entities,
        def.entity,
        layoutForm?.settings?.lookupQueries,
        properties,
        def.column,
      );

      const isDisabled = column?.isDisabled
        ? (item: SystemIntFk) => column.isDisabled(properties, item)
        : R.always(false);

      const dependencies = column?.getDependencies
        ? column.getDependencies(properties)
        : undefined;

      return (
        <td key={i}>
          {def.isRelatedEntity && !!relatedSummary ? (
            <RelatedSummaryComponent
              context={context}
              relatedEntityName={relatedEntityName}
              summary={R.find(
                (rs) => rs.name === relatedEntityName,
                relatedSummary,
              )}
              filterKey={
                secondaryQueries &&
                getFilterKey(secondaryQueries[relatedEntityName])
              }
            />
          ) : (
            <TableCell
              context={context}
              entity={entity}
              recordId={id}
              relatedRecordId={relatedRecordId}
              item={def.item}
              column={column}
              columnEntity={def.entity}
              hasEmphasis={def.hasEmphasis}
              withLinks={withLinks}
              readOnly={readOnly}
              additionalProps={additionalProps}
              isDisabled={isDisabled}
              dependencies={dependencies}
              widgetsMap={widgetsMap}
              value={properties[valueKey]}
              onChange={this.onChangeCell(valueKey)}
            />
          )}
        </td>
      );
    });

    const classNames = arrayToString([
      isSelected ? "x-row-selected " : undefined,
      isDeleted ? "x-row-deleted " : undefined,
      isHighlighted ? "x-row-highlighted " : undefined,
      isEditing ? "x-row-editing " : undefined,
    ]);

    return (
      <tr className={classNames}>
        {selectTd}
        {actionsColumn}
        {cells}
      </tr>
    );
  }
}
