import {
  formatDistanceToNow,
  formatDuration as getFormattedDuration,
  intervalToDuration,
  isValid,
  parseISO,
} from "date-fns";
import { format } from "date-fns-tz";
import { getUtcNow } from "common/date-time/common";
import {
  FORMAT_DATE,
  FORMAT_DATE_FULL,
  FORMAT_ISO,
  FORMAT_TIME_SHORT,
} from "common/date-time/formats";
import { getSiteTimeZone, getUserLocale } from "common/date-time/global";
import { parseToSited } from "common/date-time/internal";
import {
  DateTimeFormat,
  GenericDateTime,
  HumanizedDuration,
  SitedDateTime,
  UTCDateTime,
} from "common/date-time/types";

const formatSited = (
  sitedDate: Date,
  formatString: string,
  timezone?: string,
) =>
  format(sitedDate, formatString, {
    locale: getUserLocale(),
    timeZone: timezone ?? getSiteTimeZone(),
  });

export const formatTime = (dateTime: SitedDateTime) => {
  const sitedDate = parseToSited(dateTime);

  return sitedDate ? formatSited(sitedDate, FORMAT_TIME_SHORT) : "";
};

export const formatTimestamp = (
  timestamp: number,
  dateTimeFormat: DateTimeFormat = FORMAT_ISO,
): SitedDateTime => {
  const sitedDate = parseToSited(timestamp);

  return sitedDate && dateTimeFormat
    ? formatSited(sitedDate, dateTimeFormat)
    : "";
};

export const formatDate = (
  dateTime: UTCDateTime,
  dateTimeFormat?: DateTimeFormat,
  timezone?: string,
): SitedDateTime => {
  const sitedDate = parseToSited(dateTime, timezone);

  return sitedDate && dateTimeFormat
    ? formatSited(sitedDate, dateTimeFormat, timezone)
    : "";
};

export const formatLongDate = (date: UTCDateTime): SitedDateTime => {
  const sitedDate = parseToSited(date);

  return sitedDate ? formatSited(sitedDate, FORMAT_DATE_FULL) : "";
};

export const formatShortDate = (date: UTCDateTime): SitedDateTime => {
  const sitedDate = parseToSited(date);

  return sitedDate ? formatSited(sitedDate, FORMAT_DATE) : "";
};

export const formatDuration = (duration: Duration) =>
  duration
    ? getFormattedDuration(duration, {
        locale: getUserLocale(),
      })
    : "";

export const formatDifference = (
  from: GenericDateTime,
  to: GenericDateTime,
) => {
  const sitedFrom = parseToSited(from);
  const sitedTo = parseToSited(to);

  return sitedFrom && sitedTo
    ? getFormattedDuration(
        intervalToDuration({ start: sitedFrom, end: sitedTo }),
        {
          locale: getUserLocale(),
        },
      )
    : "";
};

export const formatDifferenceToNowFrom = (from: GenericDateTime) =>
  formatDifference(getUtcNow(), from);

export const formatDifferenceFromNowTo = (to: GenericDateTime) =>
  formatDifference(to, getUtcNow());

export const getHumanizedDifferenceFromNow = (
  dateTime: GenericDateTime,
): HumanizedDuration => {
  const localDate = parseISO(dateTime);
  return isValid(localDate)
    ? formatDistanceToNow(localDate, {
        addSuffix: true,
        locale: getUserLocale(),
      })
    : "";
};
