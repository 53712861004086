import { Component } from "react";
import {
  getHourFromDate,
  getMinutesFromDate,
  setHour,
  setMinutes,
} from "common/date-time/common";
import { TimeFormat, UTCDateTime } from "common/date-time/types";
import {
  formatTimeValue,
  is12HourFormat,
  to12HourFormat,
} from "common/widgets/time-picker/functions";
import { withDebounce } from "common/with-debounce";
import { ValueProps } from "common/with-value-for";
import { AmPmButton } from "./am-pm-button";
import { HourSlider } from "./hour-slider";
import { MinuteSlider } from "./minute-slider";

const MIDNIGHT = 12;

interface PropTypes extends ValueProps<UTCDateTime> {
  timeFormat: TimeFormat;
}

export class TimePickerComp extends Component<PropTypes> {
  static readonly displayName = "TimePicker";

  onChangeHour = (hour: number) => {
    this.props.onChange(setHour(this.props.value, hour));
  };

  onChangeMinute = (minute: number) => {
    this.props.onChange(setMinutes(this.props.value, minute));
  };

  render() {
    const { timeFormat, value, onChange } = this.props;
    const is12HourClock = is12HourFormat(timeFormat);

    const hour = value ? getHourFromDate(value) : 0;
    const minute = value ? getMinutesFromDate(value) : 0;

    const baseHour = is12HourClock ? to12HourFormat(hour) || MIDNIGHT : hour;
    const formattedTime = [baseHour, minute].map(formatTimeValue).join(":");

    return (
      <div className="x-time-picker">
        <div className="showtime">
          <span className="x-times">{formattedTime}</span>
          {is12HourClock ? (
            <AmPmButton value={value} onChange={onChange} />
          ) : undefined}
        </div>
        <div className="sliders">
          <div className="time-text">{_("Hours")}:</div>
          <HourSlider
            twelveHourFormat={is12HourClock}
            value={hour}
            onChange={this.onChangeHour}
          />
          <div className="time-text">{_("Minutes")}:</div>
          <MinuteSlider value={minute} onChange={this.onChangeMinute} />
        </div>
      </div>
    );
  }
}

export const TimePicker = withDebounce(TimePickerComp);
