import { createRef } from "react";
import * as R from "ramda";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { defaultFor } from "common";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { Sizer, Sizes } from "common/widgets/sizer";
import { DeleteButton } from "common/ui/buttons";
import { OnDisplay } from "../list/types";

interface PropTypes<T> {
  onDisplay: OnDisplay<T>;
  defaultItemSize: number;
  defaultHeight: number;
  canDelete?: boolean;
  getItemSize?: (item: T, index?: number) => number;
}

interface StateType {
  scroll: number;
}

export type Props<T> = PropTypes<T> & ValueProps<T[]>;

export class VirtualList<T = unknown> extends ValueComponent<
  T[],
  PropTypes<T>,
  StateType
> {
  static readonly displayName = "VirtualList";

  listRef = createRef<HTMLDivElement>();
  state: StateType = { scroll: undefined };

  onDelete = (index: number) => () => {
    const { value } = this.props;
    this.setNewValue(R.remove(index, 1, value));
  };

  onUpdate = (index: number) => (item: T) => {
    const { value } = this.props;
    this.setNewValue(R.update(index, item, value));
  };

  setNewValue = (value: T[]) => {
    /* important that these lines be triggered in this order */
    const scroll = this.listRef.current.scrollTop;
    this.setValue(value);
    this.setState({ scroll });
  };

  listItem = (
    listProps: ListChildComponentProps = defaultFor<ListChildComponentProps>(),
  ) => {
    const { index, style } = listProps;
    const { value = [], onDisplay, canDelete } = this.props;
    return (
      <div style={style} className="x-list-item qa-virtual-list-item">
        <div className="x-list-item-content">
          {onDisplay(value[index], this.onUpdate(index), index)}
        </div>
        {canDelete && (
          <DeleteButton
            className="x-margin-left-10 qa-virtual-list-item-delete"
            onClick={this.onDelete(index)}
            title={_("Remove")}
          >
            <i className="fa fa-close" />
          </DeleteButton>
        )}
      </div>
    );
  };

  getItemSize = (index: number) => {
    const { value = [], getItemSize, defaultItemSize } = this.props;
    return getItemSize ? getItemSize(value[index], index) : defaultItemSize;
  };

  virtualList = ({ width }: Sizes) => {
    const {
      value = [],
      defaultItemSize,
      defaultHeight,
      getItemSize,
    } = this.props;

    const itemsHeight = value.length * defaultItemSize;
    const listHeight =
      itemsHeight < defaultHeight
        ? getItemSize
          ? R.sum(value.map(getItemSize))
          : itemsHeight
        : defaultHeight;

    return (
      <VariableSizeList
        className="x-virtual-list x-list-widget-with-border qa-virtual-list"
        height={listHeight}
        width={width}
        itemCount={value.length}
        itemSize={this.getItemSize}
        outerRef={this.listRef}
      >
        {this.listItem}
      </VariableSizeList>
    );
  };

  shouldComponentUpdate() {
    return R.isNil(this.state.scroll);
  }

  componentDidUpdate() {
    const { scroll } = this.state;
    const currentScroll = this.listRef.current.scrollTop;
    if (!R.isNil(scroll)) {
      if (currentScroll !== scroll) {
        this.listRef.current.scrollTo({ top: scroll });
      }
      this.setState({ scroll: undefined });
    }
  }

  render() {
    const { value = [] } = this.props;
    return <Sizer key={JSON.stringify(value)} render={this.virtualList} />;
  }
}
