import { createRef, ChangeEvent, RefObject } from "react";
import { ValueComponent } from "common/with-value-for";
import { withValue, WithValue } from "common/with-value";
import { HighlightProps, Label } from "../label";
import { MaxLength } from "../max-length";

interface PropTypes {
  maxLength?: number;
  inputId?: string;
  placeholder?: string;
  autoHeight?: boolean;
  withHint?: boolean;
  label?: string;
}

const PIXELS_TO_AVOID_SHOWING_SCROLLBAR_WHEN_RESIZING = 4;

export class Text extends ValueComponent<string, PropTypes> {
  static readonly displayName = "Text";
  textAreaRef: RefObject<HTMLTextAreaElement> = createRef();

  componentDidUpdate() {
    if (this.props.autoHeight) {
      this.adjustHeight();
    }
  }

  componentDidMount() {
    if (this.props.autoHeight) {
      this.adjustHeight();
    }
  }

  adjustHeight = () => {
    const ref = this.textAreaRef.current;
    if (ref) {
      // reset the height to enforce decreasing scrollHeight
      // otherwise scrollHeight only grows and does not shrink to lower values
      // and textarea would not come back to a smaller minimum height
      ref.style.height = "auto";
      ref.style.height =
        ref.scrollHeight +
        PIXELS_TO_AVOID_SHOWING_SCROLLBAR_WHEN_RESIZING +
        "px";
    }
  };

  onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { maxLength } = this.props;
    const oldValue = this.props.value;
    const newValue = e.target.value;

    if (
      maxLength &&
      oldValue &&
      oldValue.length >= maxLength &&
      newValue &&
      newValue.length > maxLength
    )
      return;

    const value =
      maxLength && newValue && newValue.length > maxLength
        ? newValue.substring(0, maxLength)
        : newValue;

    this.setValue(value);
  };

  render() {
    const { value, maxLength, inputId, placeholder, label, autoHeight } =
      this.props;
    const val =
      maxLength && value && value.length > maxLength
        ? value.substring(0, maxLength)
        : value || "";
    const className = autoHeight
      ? "x-text x-auto-height-mode"
      : "x-text x-fixed-height-mode";

    return (
      <textarea
        className={className}
        id={inputId}
        value={val}
        onChange={this.onChange}
        placeholder={placeholder}
        aria-label={label}
        ref={this.textAreaRef}
      />
    );
  }
}

const MAX_LENGTH = 1000;
const Text1000 = (props: PropTypes & WithValue<string>) => {
  const { value, maxLength, withHint = true, onChangeSetValue } = props;
  const ml = maxLength && maxLength < MAX_LENGTH ? maxLength : MAX_LENGTH;

  return (
    <div className="x-t1000">
      <MaxLength maxLength={ml} value={value} withHint={withHint}>
        <Text {...props} onChange={onChangeSetValue} maxLength={ml} />
      </MaxLength>
    </div>
  );
};

export const T1000 = withValue<string, PropTypes>(Text1000, "Text1000");

export interface ReadOnlyPropTypes extends HighlightProps {
  value: string;
}

export const ReadOnlyText = (props: ReadOnlyPropTypes) => (
  <Label
    className="x-readonly-text"
    value={props.value}
    textToHighlight={props.textToHighlight}
  />
);
