import * as R from "ramda";
import { defaultFor } from "common";
import { getError } from "common/api/error";
import { recordsApi } from "common/api/records";
import { areTimeRangesOverlapping } from "common/date-time/helpers/scheduler";
import { TimeRange } from "common/date-time/types";
import { isRangeValid } from "common/date-time/validators";
import { Entities, Entity } from "common/entities/types";
import { getRelatedEntityNameByPath } from "common/functions/path";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { getForeignKeyValues } from "../functions";
import { AvailabilityResult, EventProperties } from "../types";
import { Event, ParsedEvent } from "./types";

export const create = (message: string): AvailabilityResult => ({ message });

export const onSuccess = (results: Event[] = []): AvailabilityResult => {
  if (!results.length) return undefined;
  return {
    message: _(
      "The selected resources are not available for the specified dates",
    ),
    details: results,
  };
};

export const onError = (error: ApiErrorResponse): AvailabilityResult =>
  create(error ? getError(error) : _("Error checking availability"));

export const checkAssignmentAvailability = (
  assignmentProps: EventProperties,
  entity: Entity,
  context: Context,
): CancellablePromise<AvailabilityResult | void> => {
  const { rangeFrom, rangeTo, id } = assignmentProps;

  if (!rangeFrom || !rangeTo) return CancellablePromise.resolve();

  if (!isRangeValid(rangeFrom, rangeTo)) {
    return CancellablePromise.reject(create(_("Date range is invalid.")));
  }

  const fkValues = getForeignKeyValues(entity, assignmentProps);

  if (!fkValues) return CancellablePromise.resolve();

  return recordsApi(context.apiCall)
    .checkAvailability(entity.name, id, rangeFrom, rangeTo, fkValues)
    .then(onSuccess)
    .catch(onError);
};

// TODO: consider removing this function, it seems to use an old API,
// cause label doesn't exist in Event type
export const parse = (entities: Entities, event: Event) => {
  const { entityName } = event;
  const related = R.compose(
    R.map((k: string) => {
      const path = k.replace(/\/label$/, "");
      return {
        id: (event as any)[path] as string,
        label: (event as any)[k] as string,
        entityName: getRelatedEntityNameByPath(entities, entityName, path),
      };
    }),
    (keys: string[]) => R.filter((k: string) => !!k.match(/\/label$/), keys),
    R.keys,
  )(event);

  return R.compose<Event, Event, ParsedEvent>(
    R.mergeRight({ related }),
    R.pick(["id", "type", "entityName", "rangeFrom", "rangeTo"]),
  )(event);
};

const defaultEventProps = defaultFor<EventProperties>();
const defaultForeignKey = defaultFor<ForeignKey>();

export const eventPropertiesChanged = (
  oldProperties: EventProperties = defaultEventProps,
  newProperties: EventProperties = defaultEventProps,
) => {
  const oldTarget = oldProperties.targetId || defaultForeignKey;
  const newTarget = newProperties.targetId || defaultForeignKey;

  return (
    oldProperties.rangeTo !== newProperties.rangeTo ||
    oldProperties.rangeFrom !== newProperties.rangeFrom ||
    oldProperties.ownerId !== newProperties.ownerId ||
    oldTarget.id !== newTarget.id
  );
};

export const isEventAlreadyAdded = (
  eventProps: EventProperties,
  uiFormProperties: Properties[],
) => {
  const id = eventProps?.targetId?.id;
  return R.any(
    (p) =>
      !!id &&
      id === p.targetId.id &&
      areTimeRangesOverlapping(eventProps, p as TimeRange),
    uiFormProperties,
  );
};
