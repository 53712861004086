import { behaveAs, getColumn, getUrl as getUrlFn } from "common/entities";
import { Entity } from "common/entities/types";
import { getDefaultHeader } from "common/form/functions/header";
import { Header } from "common/form/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { ImageWithUploadController } from "common/widgets/image-with-upload/controller";
import { Image } from "common/widgets/image";
import { withValue, WithValue } from "common/with-value";
import { getHeaderInfo } from "../utils";
import { ArchivedInfo } from "./archived-info";
import { FkInfo } from "./fk-info";
import { IdInfo } from "./id-info";
import { SubtitleInfo } from "./subtitle-info";
import { TitleInfo } from "./title-info";

interface PropTypes {
  context: Context;
  entity: Entity;
  layout: Header;
  withLinks: boolean;
  withTitleLink?: boolean;
  disableEdit?: boolean;
  getUrl?: (id: string | number) => string;
}

// TODO lots of similarities here with foreign keys and LinkFk implementation
// consider refactoring
const RecordInfoComp = ({
  context,
  entity,
  layout,
  withLinks,
  withTitleLink,
  disableEdit,
  value = {},
  onChangeMergeValue,
  getUrl,
}: PropTypes & WithValue<Properties>) => {
  const { site } = context;
  const {
    scheduledEventId,
    requestId,
    parentId,
    id: recordId,
    isDeleted,
  } = value;
  const newLayout = { ...getDefaultHeader(entity), ...layout };

  const { title, subtitle, image, id } = getHeaderInfo(
    value,
    entity,
    newLayout,
  );

  const titleLink =
    getUrl?.(recordId) || `${getUrlFn(entity, site.name)}/${recordId}`;
  const titleValue = withTitleLink ? { ...title, link: titleLink } : title;

  const isNew = !recordId;
  const isImage = !withTitleLink && behaveAs("Attachment", entity);

  const alignClass = !withTitleLink && image ? "x-vert-align-middle" : "";

  return (
    <div className="x-info-row">
      {isImage && !isNew ? (
        <div className="x-img-container">
          {disableEdit ? (
            <Image value={image} />
          ) : (
            <ImageWithUploadController
              context={context}
              entityName={entity.name}
              recordId={recordId}
              isDefault={true}
              allowClear={true}
              formValidation={undefined}
              onFormValidationChange={undefined}
              value={image}
              onChange={onChangeMergeValue("image")}
            />
          )}
        </div>
      ) : undefined}
      <div className={`x-info qa-info ${alignClass}`}>
        <TitleInfo
          context={context}
          entity={entity}
          value={titleValue}
          columnName={newLayout.title}
        />
        {subtitle ? (
          <SubtitleInfo
            context={context}
            entity={entity}
            isNew={isNew}
            columnName={newLayout.subtitle}
            value={subtitle}
          />
        ) : undefined}
        {isDeleted ? <ArchivedInfo /> : undefined}
        <IdInfo
          context={context}
          entity={entity}
          id={id}
          isNew={isNew}
          layoutId={newLayout.id}
        />
        {scheduledEventId ? (
          <FkInfo
            context={context}
            column={getColumn(entity, "scheduledEventId")}
            withLinks={withLinks}
            value={scheduledEventId}
          />
        ) : undefined}
        {requestId ? (
          <FkInfo
            context={context}
            column={getColumn(entity, "requestId")}
            withLinks={withLinks}
            value={requestId}
          />
        ) : undefined}
        {parentId ? (
          <FkInfo
            context={context}
            column={getColumn(entity, "parentId")}
            withLinks={withLinks}
            value={parentId}
            label={_("Follow-up Parent")}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export const RecordInfo = withValue<Properties, PropTypes>(
  RecordInfoComp,
  "RecordInfo",
);
