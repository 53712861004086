import { find } from "ramda";
import {
  ApiErrorResponse,
  ApiErrorResponsePayload,
  ErrorMatch,
} from "common/types/error";
import { translateVisibilityScope } from "x/account-settings/roles/detail/entity-security-settings/functions";
import { VisibilityScope } from "common/types/roles";

const errorList: ErrorMatch[] = [
  {
    regex: /^Password incorrect/, // for explicit auth
    translate: () => _("Invalid password."),
  },
  {
    regex: /^Invalid tenant/,
    translate: () => _("The tenant is invalid."),
  },
  {
    regex: /^Invalid site/,
    translate: () => _("The given site is invalid."),
  },
  {
    regex: /requires explicit authentication$/,
    translate: () => _("The action requires the e-signature."),
  },
  {
    regex: /^UserName already exists$/,
    translate: () =>
      _(
        "The provided Email is already assigned to another user, please use a different address.",
      ),
  },
  {
    regex: /^Invalid entity.*already exists$/,
    translate: () => _("An entity with the same name already exists."),
  },
  {
    regex: /^Invalid entity/,
    translate: () => _("Invalid Entity"),
  },
  {
    regex: /^Existing form names for entity/,
    translate: () => _("A form with the same name and entity already exists."),
  },
  {
    regex: /^Location already in use for part$/,
    translate: () =>
      _("The provided location is already in use for another part."),
  },
  {
    regex: /^Invalid object name: (.*)/,
    translate: (error: string, match: RegExp) => {
      const [, expression] = match.exec(error);
      return _(
        "The custom expression {EXPRESSION} is invalid, please correct it and try again",
      ).replace("{EXPRESSION}", expression);
    },
  },
  {
    regex: /^Invalid .* WorkOrders .* Cannot update charges$/,
    translate: () =>
      _("Charges cannot be edited for WorkOrder with the current status"),
  },
  {
    regex: /^Invalid .* command issued/,
    translate: () => _("The record cannot be edited"),
  },
  {
    regex:
      /^Entity .* must implement ExternalId Behavior in order to sync information$/,
    translate: () =>
      _(
        'Asset entity requires the "Fluke Connect Sync" behavior to sync information.',
      ),
  },
  {
    regex: /^The .* has descendants$/,
    translate: () =>
      _("We could not complete your request. Record has descendants."),
  },
  {
    regex: /^Record in use$/,
    translate: () => _("The record is in use and cannot be deleted."),
  },
  {
    regex: /^No live Fluke data found/,
    translate: () =>
      _("No data found, you may find data with another Time Range"),
  },
  {
    regex: /^Record of type (.*) with id (.*) was not found/,
    translate: (error: string, match: RegExp) => {
      const [, type, id] = match.exec(error);
      return _("Record of type {TYPE} with id {ID} was not found")
        .replace("{TYPE}", type)
        .replace("{ID}", id);
    },
  },
  {
    regex: /^One or more items are not processed/,
    translate: () => _("One or more items are not processed"),
  },
  {
    regex: /^One or more items are approved/,
    translate: () => _("One or more items are approved"),
  },
  {
    regex: /^You need at least one item approved/,
    translate: () => _("You need at least one item approved"),
  },
  {
    regex: /^Empty File Received/,
    translate: () => _("No data to process, please upload a new csv file"),
  },
  {
    regex: /^Fluke Mobile enablement failed/,
    translate: () => _("Fluke Mobile enablement failed"),
  },
  {
    regex:
      /^Cannot delete calendar because is in use by a scheduled work order/,
    translate: () =>
      _("Cannot delete calendar because is in use by a scheduled work order"),
  },
  {
    regex:
      /^There was a problem running your query. Please check your filters and try again.$/,
    translate: () =>
      _(
        "There was a problem running your query. Please check your filters and try again.",
      ),
  },
  {
    regex: /^Duplicated columns in the imported file: (.*)/,
    translate: (error: string, match: RegExp) => {
      const [, colname] = match.exec(error);
      return _("Duplicated columns in the imported file: {COLNAME}").replace(
        "{COLNAME}",
        colname,
      );
    },
  },
  {
    regex: /^Cost Limit exceeded (.*)/,
    translate: (error: string, match: RegExp) => {
      const [, limit] = match.exec(error);
      return _("Cost limit exceeded, your value is over: {LIMIT}").replace(
        "{LIMIT}",
        limit,
      );
    },
  },
  {
    regex: /^No measurements available for the selected time range./,
    translate: () =>
      _("No measurements available for the selected time range."),
  },
  {
    regex: /Missing permission for (.*?) entity$/,
    translate: (error: string, match: RegExp) => {
      const [, entity] = match.exec(error);
      return _("Missing permission for {ENTITY} entity").replace(
        "{ENTITY}",
        entity,
      );
    },
  },
  {
    regex: /^Missing permission for an entity with (.*?) behavior$/,
    translate: (error: string, match: RegExp) => {
      const [, behaviorName] = match.exec(error);
      return _(
        "Missing permission for an entity with {BEHAVIOR NAME} behavior",
      ).replace("{BEHAVIOR NAME}", behaviorName);
    },
  },
  {
    regex: /^The expression can not be empty/,
    translate: () => _("The expression can not be empty"),
  },
  {
    regex: /^The expression contains invalid words: (.*)/,
    translate: (error: string, match: RegExp) => {
      const [, invalidWords] = match.exec(error);
      return _("Invalid words: {INVALID_WORDS}").replace(
        "{INVALID_WORDS}",
        invalidWords,
      );
    },
  },
  {
    regex: /^The expression contains invalid Replacement Fields/,
    translate: () => _("Invalid Replacement Fields"),
  },
  {
    regex: /^Password has to be different from the last \d+ password\(s\)$/,
    translate: () => _("Password was already used"),
  },
  {
    regex: /^Invalid email address./,
    translate: () => _("Invalid email address."),
  },
  {
    regex: /^Your User License only permits viewing (\w*) (.*)./,
    translate: (error: string, match: RegExp) => {
      const [, scope, entityName] = match.exec(error);
      const translateScope = translateVisibilityScope(scope as VisibilityScope);

      return (
        _("Your User License only permits viewing {SCOPE} {ENTITY}.")
          .replace("{SCOPE}", translateScope)
          .replace("{ENTITY}", entityName) +
        " " +
        _(
          "Please contact the system administrator if further assistance is required.",
        )
      );
    },
  },
];

const errorMatches = (message: string, regex: RegExp) => regex.test(message);

const translateErrorMessage = (message: string) => {
  const match: ErrorMatch = find(
    (error: ErrorMatch) => errorMatches(message, error.regex),
    errorList,
  );

  return match ? match.translate(message, match.regex) : undefined;
};

const translateErrorMessages = (messages: string[]) =>
  messages.map(translateErrorMessage).join("; ");

const getTranslatedError = (data: ApiErrorResponsePayload) =>
  Array.isArray(data?.message)
    ? translateErrorMessages(data?.message)
    : translateErrorMessage(data?.error || data?.message);

const get400Error = (data: ApiErrorResponsePayload) =>
  getTranslatedError(data) || _("There was a problem with your request.");

const get401Error = () => _("Unauthorized access.");

const get403Error = (data: ApiErrorResponsePayload) =>
  getTranslatedError(data) ||
  _(
    "You do not have enough permissions to perform this action, please contact the system administrator.",
  );

const getChunkLoadingError = () =>
  _(
    "There was a problem loading the module. Please make sure you have an Internet connection and refresh the page.",
  );

export const getUnexpectedErrorMessage = () =>
  _(
    "An unexpected error seems to have occurred. Please try refreshing the page. You can also contact our support team if the problem persists.",
  );

const get404Error = (data: ApiErrorResponsePayload) =>
  getTranslatedError(data) || _("Not found");

const getUsersLimitExceededError = (data: ApiErrorResponsePayload) =>
  _("Maximum number of {TYPE} users ({MAXUSERS}) reached.")
    .replace("{TYPE}", data.type)
    .replace("{MAXUSERS}", data.maxUsers.toString());

export const isCancelError = (error: ApiErrorResponse) => error?.cancel;

const recordAlreadyExists = (error: ApiErrorResponse) => !!error?.data?.unique;

const isLoadingChunkError = (error: any) => error?.name === "ChunkLoadError";

const usersLimitExceeded = (error: ApiErrorResponse) =>
  !!(error?.data?.maxUsers && error?.data?.type);

export const getError = (error: ApiErrorResponse): string => {
  if (isCancelError(error)) return undefined;

  if (recordAlreadyExists(error)) return _("Record already exists.");
  if (usersLimitExceeded(error)) return getUsersLimitExceededError(error?.data);
  if (isLoadingChunkError(error)) return getChunkLoadingError();

  switch (error?.status) {
    case 404:
      return get404Error(error?.data);
    case 403:
      return get403Error(error?.data);
    case 401:
      return get401Error();
    case 400:
      return get400Error(error?.data);
  }

  return getUnexpectedErrorMessage();
};

export const DISABLED_VENDOR_PORTAL =
  "The Vendor Portal is currently disabled for your account";
