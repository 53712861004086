import { defaultFor, getLocalizedName } from "common";
import { Event, RecordEvent } from "common/types/events";
import { FORMAT_DATE_FULL } from "common/date-time/formats";
import { formatDate } from "common/date-time/formatters";
import {
  getEventEntityName,
  getEventLocalizedName,
} from "common/record/form/content/audit-trail/utils";
import { Context } from "common/types/context";
import { LinkRecord } from "common/widgets/link-record";

interface PropTypes {
  event: Event;
  entityName: string;
  context: Context;
  recordId: string;
  getUrl?: (id: string | number) => string;
}

export const EventListItem = ({
  event,
  entityName,
  context,
  recordId,
  getUrl,
}: PropTypes) => {
  const { entities, site } = context;
  const { id, details = defaultFor<RecordEvent>() } = event;
  const {
    occurredOn,
    entityName: detailsEntityName = "",
    triggerRecordId = recordId,
  } = details;

  const eventOccurredOn = occurredOn || event.occurredOn || "";

  const localizedValue = _("by {USERNAME} on {DATE}")
    .replace("{USERNAME}", event.userName)
    .replace("{DATE}", formatDate(eventOccurredOn, FORMAT_DATE_FULL));

  const eventEntityName = getEventEntityName(event.name) || detailsEntityName;
  const relatedEntityName =
    entityName !== eventEntityName || triggerRecordId !== recordId
      ? " [" + getLocalizedName(entities[eventEntityName]) + "]"
      : "";

  const className =
    entityName === detailsEntityName
      ? "x-event-list-item-title-main-entity"
      : "x-event-list-item-title";

  return (
    <LinkRecord
      site={site.name}
      entity={entities[entityName]}
      id={recordId}
      auditTrailId={id}
      getUrl={getUrl}
    >
      <div className={className}>
        {getEventLocalizedName(event.name, event.userName) + relatedEntityName}
      </div>
      <div className="x-event-list-item-value">{localizedValue}</div>
    </LinkRecord>
  );
};

EventListItem.displayName = "EventListItem";
