export const getLogoUrls = (
  tenantName?: string,
  lightMode: boolean = false,
) => {
  const query = tenantName ? `?tenantName=${tenantName}` : "";
  const mode = lightMode ? "/light" : "";

  return {
    desktopLogo: `/api/branding/desktop${mode}${query}`,
    mobileLogo: `/api/branding/mobile${mode}${query}`,
  };
};
