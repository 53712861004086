import * as R from "ramda";
import { InputWithSearch } from "common/widgets/input-with-search";
import { Tree } from "common/widgets/tree";
import { Modal } from "common/widgets/modal";
import { getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Site } from "common/types/sites";
import { Selector } from "common/widgets/selector";
import { ValueComponent } from "common/with-value-for";
import { isGroupSite } from "x/account-settings/sites/functions";
import { getDisplaySiteFn } from "../site-tree-selector-with-search/functions";
import { getSingleSites, getSitesForTree } from "./functions";

interface PropTypes {
  context: Context;
  disabled?: boolean;
  sitesToOmit?: Site[];
  className?: string;
  includeGroupSites?: boolean;
  hideTreeSelector?: boolean;
}

interface StateType {
  treeSearchTerm: string;
  isTreeSelectorOpen: boolean;
}

export class SiteSelector extends ValueComponent<string, PropTypes, StateType> {
  static readonly displayName = "SiteSelector";
  state: StateType = {
    treeSearchTerm: undefined,
    isTreeSelectorOpen: false,
  };

  onSiteChange = (site: Site) => {
    this.setValue(site?.name);
  };

  onTreeSelectorClick = () => {
    this.setState({ isTreeSelectorOpen: true });
  };

  onTreeSelectorChange = (site: Site) => {
    const { includeGroupSites } = this.props;

    if (isGroupSite(site) && !includeGroupSites) return;

    this.setValue(site?.name);
    this.setState({ isTreeSelectorOpen: false });
  };

  onCancelClick = () => {
    this.setState({ isTreeSelectorOpen: false });
  };

  isSiteDisabled = (site: Site) => {
    const { sitesToOmit, includeGroupSites } = this.props;

    return !!(
      (sitesToOmit && sitesToOmit.some((s) => s.name === site.name)) ||
      (!includeGroupSites && isGroupSite(site))
    );
  };

  onTreeSearchTermChange = (searchTerm: string) => {
    this.setState({ treeSearchTerm: searchTerm });
  };

  render() {
    const {
      className,
      context,
      sitesToOmit,
      includeGroupSites,
      hideTreeSelector,
      disabled,
      value,
    } = this.props;
    const { treeSearchTerm, isTreeSelectorOpen } = this.state;
    const sitesDictionary = getSitesDictionary(context.sites);
    const sites = sitesToOmit
      ? context.sites.filter((s) => !sitesToOmit.includes(s))
      : context.sites;
    const sitesForSelector = includeGroupSites ? sites : getSingleSites(sites);
    const sitesForTree = getSitesForTree(context.sites, treeSearchTerm);

    return (
      <div className="x-site-selector-wrapper qa-site-selector">
        <Selector<Site>
          className={className}
          placeholder={_("Select a site")}
          getOptionLabel={getDisplaySiteFn(sitesDictionary)}
          options={sitesForSelector}
          disabled={disabled}
          value={R.find((s) => s.name === value, sites)}
          onChange={this.onSiteChange}
        />
        {!hideTreeSelector && sitesForSelector.length > 1 ? (
          <div className="x-tree-selector">
            <button
              className={`btn x-tree-selector-button qa-tree-selector-button`}
              title={_("Sites Tree Selector")}
              onClick={this.onTreeSelectorClick}
            >
              <i className="fa fa-sitemap" />
            </button>
          </div>
        ) : undefined}

        {isTreeSelectorOpen ? (
          <Modal
            title={_("Select Site")}
            content={
              <>
                <InputWithSearch
                  value={treeSearchTerm}
                  onChange={this.onTreeSearchTermChange}
                />
                {sitesForTree.length > 0 ? (
                  <Tree
                    nodes={getSitesForTree(context.sites, treeSearchTerm)}
                    selected={sitesDictionary[value]}
                    textToHighlight={treeSearchTerm}
                    isDisabled={this.isSiteDisabled}
                    onClick={this.onTreeSelectorChange}
                  />
                ) : (
                  <span className="x-no-results qa-no-results">
                    {_("No results")}
                  </span>
                )}
              </>
            }
            className="x-tree-selector-content qa-tree-selector-modal"
            hideFooter={true}
            noPadding={true}
            onCancel={this.onCancelClick}
          />
        ) : undefined}
      </div>
    );
  }
}
