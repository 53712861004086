import { escapeRegex } from "common/utils/regexp";

interface PropTypes {
  text: string;
}
export const highlight = (text: string) => `~highlight:${text}:endhighlight~`;

export const getLabelWithHighlight = (
  label: string,
  textToHighlight: string = "",
) => {
  if (!label || !textToHighlight.length) return label ?? "";
  const highlightSanitized = escapeRegex(textToHighlight?.toLowerCase());
  return label.replace(
    new RegExp(`(${highlightSanitized})`, "ig"),
    highlight("$1"),
  );
};

export const removeHighlight = (text: string) =>
  (text || "").replace(/(~highlight:|:endhighlight~)/gi, "");

export const Highlight = ({ text = "" }: PropTypes) => {
  const [before, ...rest] = text.split("~highlight:");
  if (!rest.length) return <span>{text}</span>;

  const items = rest.map((h, i) => {
    const [hl, after] = h.split(":endhighlight~");
    return (
      <span key={i}>
        <i>{hl}</i>
        {after}
      </span>
    );
  });

  return (
    <span className="x-keyword-highlight">
      {before}
      {items}
    </span>
  );
};
Highlight.displayName = "Highlight";
