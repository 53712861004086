import { getWorkweekRangeForDate } from "common/date-time/calculators";
import {
  getEndOfForDateWithViewType,
  getStartOfForDateWithViewType,
} from "common/date-time/helpers/scheduler-bryntum";
import type { UTCDateRange, UTCDateTime } from "common/date-time/types";
import type { Entity } from "common/entities/types";
import { hasPermissionsTo } from "common/functions/roles";
import type { UserType } from "common/functions/users";
import type { Role } from "common/types/roles";
import type { ViewType as CommonViewType } from "common/types/scheduler";
import type { SchedulerData, ViewType } from "x/scheduler2/types";

export const isPlanner = (
  userTypes: UserType[],
  role: Role,
  assignmentsEntities: Entity[] = [],
) =>
  assignmentsEntities.some((entity) =>
    hasPermissionsTo(userTypes, role, entity.name, [
      "Create",
      "Update",
      "Delete",
      "Publish",
    ]),
  );

export const getCurrentDateRange = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateRange => ({
  from: getStartOfForDateWithViewType(date, view),
  to: getEndOfForDateWithViewType(date, view),
});

export const getWorkingDateRange = (value: SchedulerData): UTCDateRange => {
  const { selectedDate, view, hideWeekends } = value;

  return view === "week" && hideWeekends
    ? getWorkweekRangeForDate(selectedDate)
    : getCurrentDateRange(selectedDate, view);
};

export const translateViewForPreferences = (view: ViewType) =>
  view === "month" ? "customMonth" : view;

export const translateViewFromPreferences = (view: CommonViewType) =>
  view === "customMonth" ? "month" : view;
