interface PropTypes {
  url: string;
  title?: string;
}

export const PopOut = ({ url, title }: PropTypes) => (
  <a
    className="btn btn-sm x-popout"
    href={url}
    target="_blank"
    title={title}
    rel="noopener noreferrer"
  >
    <i className="fa fa-share-square-o" />
  </a>
);

PopOut.displayName = "PopOut";
