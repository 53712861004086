import { useEffect, useState } from "react";
import { cycleCountApi, PartsDetailsResponse } from "common/api/cycle-count";
import { behaveAs, getRelatedEntities } from "common/entities";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import {
  areRecordsReadyToSubmit,
  getPartsToBeCountedWithInput,
  getValidPartsToBeCounted,
  mapResponse,
  mapToSubmitAllCount,
} from "common/record/actions/ui/submit-parts-count/functions";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { defaultFor } from "common/index";
import { SubmitAllActionTypes } from "common/record/actions/ui/submit-parts-count/types";
import { SubmitWithoutInput } from "common/record/actions/ui/submit-parts-count/submit-without-input";
// eslint-disable-next-line import/no-cycle
import { SubmitWithInput } from "common/record/actions/ui/submit-parts-count/submit-with-input";

export const SubmitPartsCount = (props: PropTypes) => {
  const { context, entity, records, dismiss } = props;
  const { entities, apiCall } = context;
  const cycleCountRecord = records[0]?.properties ?? defaultFor<Properties>();
  const partsToBeCountedEntity = getRelatedEntities(entity, entities).find(
    (entity) => behaveAs("PartsToBeCounted", entity),
  );
  const partLocationEntityName = partsToBeCountedEntity?.arguments?.stockEntity;

  const [totalPartsToBeCounted, setTotalPartsToBeCounted] = useState<number>(0);
  const [validPartsToBeCounted, setValidPartsToBeCounted] = useState<
    SubmitAllActionTypes[]
  >([]);
  const [partsToBeCountedWithInput, setPartsToBeCountedWithInput] = useState<
    SubmitAllActionTypes[]
  >([]);
  const [loadingPartsDetails, setLoadingPartsDetails] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<ApiErrorResponse>(undefined);

  useEffect(() => {
    setLoadingPartsDetails(true);
    cycleCountApi(apiCall)
      .getPartsDetails(entity.name, cycleCountRecord.id)
      .then((response: PartsDetailsResponse[] = []) => {
        const partDetails = mapResponse(response);
        setTotalPartsToBeCounted(partDetails.length);
        setValidPartsToBeCounted(getValidPartsToBeCounted(partDetails));
        setPartsToBeCountedWithInput(getPartsToBeCountedWithInput(partDetails));
        setLoadingPartsDetails(false);
      })
      .catch(() => {
        // Error is handled by the API call function
        setLoadingPartsDetails(false);
      });
  }, []);

  const onSubmit = (apiCall: ApiCall, dismiss: DismissAction) => {
    setSubmitting(true);
    return cycleCountApi(apiCall)
      .submitAll(
        entity.name,
        cycleCountRecord.id,
        totalPartsToBeCounted,
        mapToSubmitAllCount(validPartsToBeCounted),
      )
      .then(() => {
        dismiss(true);
        setSubmitting(false);
      })
      .catch((error) => {
        // Error is handled by the API call function
        setError(error);
        dismiss(true);
      });
  };

  const onContentChange = (value: SubmitAllActionTypes) => {
    const newValidPartsToBeCountedRecords = validPartsToBeCounted.map(
      (record) =>
        record?.partsToBeCountedId === value.partsToBeCountedId
          ? { ...record, ...value }
          : record,
    );
    setValidPartsToBeCounted(newValidPartsToBeCountedRecords);
  };

  if (loadingPartsDetails) return <LoadingIcon />;
  if (!validPartsToBeCounted.length) return null;
  if (!loadingPartsDetails && partsToBeCountedWithInput.length === 0) {
    return (
      <SubmitWithoutInput
        context={context}
        entity={entity}
        dismiss={dismiss}
        validPartsToBeCounted={validPartsToBeCounted}
        cycleCountId={cycleCountRecord?.id}
      />
    );
  }

  return (
    <Action
      {...props}
      requiresAuthentication={hasProtectedColumns(entity)}
      title={_("Submit")}
      btnLabel={_("Submit")}
      size="large"
      onOk={
        areRecordsReadyToSubmit(validPartsToBeCounted) ? onSubmit : undefined
      }
    >
      {submitting ? <LoadingIcon /> : undefined}
      {error ? <ApiError error={error} /> : undefined}
      <div>
        {partsToBeCountedWithInput.map((record) => (
          <SubmitWithInput
            key={record.partsToBeCountedId}
            context={context}
            partLocationEntityName={partLocationEntityName}
            partsToBeCountedEntity={partsToBeCountedEntity}
            value={record}
            onChange={onContentChange}
          />
        ))}
      </div>
    </Action>
  );
};
