import { JSX } from "react";
import { Component } from "common/component";
import { LoadingIcon } from "common/widgets/loading-icon";

/* eslint-disable react/no-unused-state */

interface PropTypes {
  src: string;
  className?: string;
  alt?: string;
  onClick?: () => void;
  errorComp?: JSX.Element;
  stopReload?: boolean;
  onError?: () => void;
}

interface StateType {
  imageLoading: boolean;
  imageError: boolean;
  src: string;
}

export class ImageWithLoading extends Component<PropTypes, StateType> {
  static readonly displayName = "ImageWithLoading";

  static getDerivedStateFromProps(nextProps: PropTypes, prevState: StateType) {
    const { src, stopReload } = nextProps;
    const prevSrc = prevState?.src;

    if (!stopReload && prevSrc !== src) {
      return {
        imageLoading: true,
        imageError: false,
        src,
      };
    }
    return null;
  }

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      imageLoading: true,
      imageError: false,
      src: props.src,
    };
  }

  onImageLoaded = () => {
    this.setState({ imageLoading: false, imageError: false });
  };

  onImageError = () => {
    const { onError } = this.props;
    if (onError) onError();
    this.setState({ imageLoading: false, imageError: true });
  };

  render() {
    const { className = "", alt, onClick, errorComp = null } = this.props;
    const { imageLoading, imageError, src } = this.state || {};

    const loadingClass = imageLoading ? "x-image-loading" : "";
    return imageError ? (
      errorComp
    ) : src ? (
      <div className={`x-image-with-loading ${loadingClass} ${className}`}>
        {imageLoading ? <LoadingIcon /> : undefined}
        <img
          onLoad={this.onImageLoaded}
          onError={this.onImageError}
          src={src}
          alt={alt}
          loading="lazy"
          onClick={onClick}
        />
      </div>
    ) : null;
  }
}
