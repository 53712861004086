import { PropsWithChildren } from "react";

interface PropTypes {
  action?: () => any;
  loading?: boolean;
}

export const ShowMore = ({
  action,
  loading,
  children,
}: PropsWithChildren<PropTypes>) => {
  return (
    <div className="x-show-more">
      <div className="x-show-more-content">{children}</div>
      {loading ? (
        <button className="x-button-action" disabled={true}>
          {_("Loading")}
          <i className="fa fa-spin fa-spinner" />
        </button>
      ) : (
        <button className="x-button-action" onClick={action}>
          {_("Show more")}
        </button>
      )}
    </div>
  );
};

ShowMore.displayName = "ShowMore";
