import { ReactNode, useState } from "react";
import ReactModal from "react-modal";
import { arrayToString } from "common/utils/array";
import { CancelButton, DeleteButton, ModifyButton } from "common/ui/buttons";

export interface BasePropTypes {
  content: ReactNode;
  size?: "small" | "medium" | "large";
  noPadding?: boolean;
  className?: string;
  title?: string;
  onContentClick?: () => void;
  hideFooter?: boolean;
  okLabel?: string;
  onOk?: () => void;
  footerButtons?: ReactNode;
  allowFullscreen?: boolean;
  onAfterOpen?: () => void;
}

interface PropTypes extends BasePropTypes {
  onCancel: () => void;
}

export const Modal = ({
  content,
  onCancel,
  size,
  noPadding,
  className = "",
  title,
  onContentClick,
  hideFooter,
  okLabel,
  onOk,
  footerButtons,
  allowFullscreen = true,
  onAfterOpen,
}: PropTypes) => {
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const classes = arrayToString([
    "x-modal",
    isFullScreen ? "x-fullscreen" : `x-${size || "medium"}`,
    hideFooter ? "x-hide-footer" : undefined,
    !title ? "x-hide-title" : undefined,
  ]);

  const onFullscreen = () => setFullScreen(!isFullScreen);

  return (
    <ReactModal
      htmlOpenClassName="x-modal-html-open"
      bodyOpenClassName="x-modal-body-open"
      portalClassName="x-modal-portal"
      overlayClassName="x-modal-backdrop"
      className={classes}
      isOpen={true}
      appElement={document.querySelector(".x-modal-parent")}
      onRequestClose={onCancel}
      onAfterOpen={onAfterOpen}
    >
      <div className={`x-modal-container ${className}`}>
        {title ? (
          <div className="x-modal-header">
            <div className="x-modal-title">{title}</div>
            <div className="x-modal-toolbar">
              {allowFullscreen ? (
                <CancelButton
                  className="qa-btn-fullscreen"
                  onClick={onFullscreen}
                  dataTestId="toggle-fullscreen"
                >
                  <i
                    className={`fa ${
                      isFullScreen ? "fa-compress" : "fa-expand"
                    }`}
                  />
                </CancelButton>
              ) : undefined}
              <DeleteButton
                className="qa-btn-x"
                onClick={onCancel}
                dataTestId="close-modal"
              >
                <i className="fa fa-times" />
              </DeleteButton>
            </div>
          </div>
        ) : undefined}

        <div
          className={`x-modal-content ${noPadding ? "" : "x-padding-20"}`}
          onClick={onContentClick}
        >
          {content}
        </div>

        {hideFooter ? undefined : (
          <div className="x-modal-footer">
            {footerButtons}
            <CancelButton className="qa-btn-cancel" onClick={onCancel} />
            <ModifyButton className="qa-btn-ok" disabled={!onOk} onClick={onOk}>
              {okLabel || _("Ok")}
            </ModifyButton>
          </div>
        )}
      </div>
    </ReactModal>
  );
};
Modal.displayName = "Modal";
