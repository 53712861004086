import * as R from "ramda";
import { getApiCallResolvingData, withSite } from "common/api";
import {
  absoluteApiCallFull,
  apiCallFullWithLoginRedirect,
} from "common/api/impl";
import { translate as translateCalendar } from "common/culture/calendar-translator";
import { translate as translateDashboard } from "common/culture/dashboard-translator";
import { translate as translateEntities } from "common/culture/entities-translator";
import { translate as translateForm } from "common/culture/form-translator";
import { SUPPORTED_CULTURES } from "common/culture/supported-cultures";
import { setEntity } from "common/entities";
import { processEntities } from "common/entities/process";
import { Entity } from "common/entities/types";
import { cleanUpForm } from "common/form/functions/cleanup";
import { filterFormsPerSite } from "common/form/functions/common";
import { findSite } from "common/functions/sites";
import { rawToSystemTables } from "common/functions/system-table";
import { anyFeatureEnabled, isEnabledFeature } from "common/functions/tenants";
import { ApiCallFull } from "common/types/api";
import { Context, RawSettings } from "common/types/context";
import { findCurrency } from "./currency";
import { lastVisitedService } from "./last-visited";
import { localStorageService } from "./local-storage";
import { preferencesService } from "./preferences";
import { pushNotificationsService } from "./push-notifications";
import { recentlyViewedService } from "./recently-viewed";
import { starredService } from "./starred";

const cleanContext = (context: Context) => {
  const { forms, entities } = context;
  const cleanedForms = forms.map((f) => cleanUpForm(f, entities[f.entityName]));
  return { ...context, forms: cleanedForms };
};

export const getContext = (
  {
    sites,
    entities,
    forms,
    dashboards,
    calendars,
    emailTemplates,
    currencies,
    userName,
    enabledUsers,
    tenant,
    preferences,
    uiFormat,
    cultures,
    id,
    version,
    announcements,
    systemTables,
    userTypes,
    starred,
    name,
    role,
    isImpersonated,
    isSystem,
    masterCurrency: masterCurrencyId,
  }: RawSettings,
  globalSiteName: string,
  apiCallFullFn?: ApiCallFull,
  xtoken?: string,
): Context => {
  const { culture } = uiFormat;
  const { full = 0, requestor = 0, flukeMobile = 0, vendor = 0 } = enabledUsers;
  const defaultCalendar = calendars.find((c) => c.settings.isDefault);
  const localStorage = localStorageService(userName, tenant.name);
  const masterCurrency = findCurrency(masterCurrencyId, currencies);
  const allForms = forms.map((f) => translateForm(culture, f));
  const translatedEntities = translateEntities(
    culture,
    processEntities(entities),
  );

  const createContextForSiteWithFallback = (siteName: string): Context => {
    const site =
      findSite(siteName, sites) || sites.find((s) => !s.isGroup) || sites[0]; // could be removed as this is being done in fns called before this

    const currency = site.isGroup
      ? masterCurrency
      : findCurrency(site.currency, currencies);

    const apiCallFull: ApiCallFull = withSite(
      apiCallFullFn || apiCallFullWithLoginRedirect,
      site.name,
    );
    const apiCall = getApiCallResolvingData(apiCallFull);
    const preferenceService = preferencesService(apiCall, preferences);
    const formsForSite = allForms.filter(filterFormsPerSite(sites, site));

    const context: Context = {
      createContextForSiteWithFallback: (newSiteName: string): Context =>
        newSiteName === site.name
          ? context
          : createContextForSiteWithFallback(newSiteName),
      createContextForSiteIfExists: (newSiteName: string): Context =>
        newSiteName === site.name || !findSite(newSiteName, sites)
          ? context
          : createContextForSiteWithFallback(newSiteName),
      apiCall,
      apiCallFull,
      absoluteApiCallFull,
      localStorage,
      recentlyViewed: recentlyViewedService(
        apiCall,
        site.name,
        localStorage,
        translatedEntities,
        preferenceService.notify,
      ),
      preferenceService,
      pushNotifications: pushNotificationsService(),
      lastVisited: lastVisitedService(localStorage),
      starred: starredService(apiCall, starred),
      id,
      role,
      name,
      userName,
      isImpersonated,
      isSystem,
      enabledUsers: {
        full,
        requestor,
        flukeMobile,
        vendor,
        total: full + requestor + flukeMobile + vendor,
      },
      tenant,
      isEnabledFeature: (feature) => isEnabledFeature(tenant.features, feature),
      anyFeatureEnabled: anyFeatureEnabled(tenant.features),
      entities: translatedEntities,
      entitiesAvailableColumns: R.mapObjIndexed(
        (entity: Entity) => ({
          ...entity,
          columns: entity.columns.filter((col) => col.availableForList),
        }),
        translatedEntities,
      ),
      setEntity,
      site,
      sites,
      getSite: (siteName: string) => findSite(siteName, sites),
      masterCurrency,
      currency,
      currencies: currencies,
      dashboards: dashboards.map((d) => translateDashboard(culture, d)),
      calendars: calendars.map((c) => translateCalendar(culture, c)),
      emailTemplates,
      defaultCalendarId: defaultCalendar?.id,
      allForms: allForms,
      forms: formsForSite,
      supportedCultures: SUPPORTED_CULTURES,
      cultures: R.intersection(
        cultures,
        SUPPORTED_CULTURES.map((c) => c.name),
      ),
      uiFormat: {
        ...uiFormat,
        displayTimezone:
          findSite(globalSiteName, sites)?.isGroup ||
          uiFormat.displayTimeZoneOnSingleSiteRecords,
      },
      announcements: R.reverse(announcements),
      version,
      systemTables: rawToSystemTables(systemTables),
      userTypes,
      xtoken,
    };

    return context;
  };
  return cleanContext(createContextForSiteWithFallback(globalSiteName));
};
