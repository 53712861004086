import * as React from "react";
import debounce from "lodash-es/debounce";
import { Cancelable } from "common/types/debounce";
import { AlertErrorTip } from "common/widgets/alert";
import { setColumnIsValid } from "common/form/functions/validation";
import { ApiError } from "common/ui/api-error";
import { ApiErrorResponse } from "common/types/error";
import { Props, YourComponentProps } from "./types";

interface StateType {
  debounceValidateValue: ((value: string) => void) & Cancelable;
  pending: boolean;
  duplicated: boolean;
  error: ApiErrorResponse;
}

export function withUnique<PropTypes>(
  YourComponent: React.ComponentType<YourComponentProps<PropTypes>>,
) {
  return class Unique extends React.Component<Props<PropTypes>, StateType> {
    static readonly displayName = "Unique";

    constructor(props: Props<PropTypes>) {
      super(props);
      this.state = {
        debounceValidateValue: debounce(this.validateValue, 250),
        pending: false,
        duplicated: false,
        error: undefined,
      };
    }

    componentDidMount() {
      const { validateOnMount = true, useUnique, value } = this.props;
      if (useUnique && validateOnMount && value) this.validateValue(value);
    }

    componentDidUpdate(prevProps: Props<PropTypes>) {
      const { useUnique, value } = this.props;
      if (useUnique && prevProps.value !== value) this.onChange(value);
    }

    componentWillUnmount() {
      const { debounceValidateValue } = this.state;
      if (debounceValidateValue) debounceValidateValue.cancel();
    }

    onChange = (value: string) => {
      const { onChange } = this.props;
      this.setState({ error: undefined });

      if (!value) {
        this.setState({ duplicated: false });
        return onChange(undefined);
      }

      onChange(value);
      this.state.debounceValidateValue(value);
    };

    validateValue = (value: string) => {
      this.setState({ pending: true });
      this.setColumnValidity(false);

      this.props
        .fetchIsUnique(value)
        .then((isUnique) => {
          this.setState({ pending: false, duplicated: !isUnique });
          this.setColumnValidity(isUnique);
        })
        .catch((error) => this.setState({ error, pending: false }));
    };

    setColumnValidity = (isValid: boolean) => {
      const { columName, formValidation, onFormValidationChange } = this.props;

      return onFormValidationChange?.(
        setColumnIsValid(formValidation, columName, isValid),
      );
    };

    render() {
      const { useUnique, columnLocalizedName, value } = this.props;
      const { pending, duplicated, error } = this.state;

      const style = pending
        ? "x-highlighted"
        : duplicated || error
          ? "x-has-error"
          : "";
      return useUnique ? (
        <div className={`x-unique ${style}`}>
          <YourComponent
            {...this.props}
            value={value}
            onChange={this.onChange}
          />
          {pending ? <i className="fa fa-spinner fa-spin" /> : undefined}
          {duplicated ? (
            <AlertErrorTip
              message={_("This COLUMN already exists").replace(
                "COLUMN",
                columnLocalizedName,
              )}
            />
          ) : error ? (
            <ApiError error={error} />
          ) : undefined}
        </div>
      ) : (
        <YourComponent {...this.props} />
      );
    }
  };
}
