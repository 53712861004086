import { arrayToString } from "common/utils/array";
import { MediaFile } from "common/record/form/content/media/file";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { Uploader } from "common/widgets/file-selector/uploader";
import { getFileType } from "common/record/form/content/media/functions";
import { AcceptType } from "../file-selector/types";
import { getFileDetails, isPendingUpload } from "./functions";

interface Props {
  onUpload: (file: FileType) => CancellablePromise<any>;
  onRemove: () => void;
  allowClear: boolean;
  url: string;
  uploaderLabel?: string;
  acceptType?: AcceptType;
}

export const DocumentWithUpload = ({
  allowClear,
  onUpload,
  onRemove,
  url,
  uploaderLabel,
  acceptType,
}: Props) => {
  const file = getFileDetails(url);

  const isEmpty = !file;
  const fileType = file && getFileType(file);
  const addClass = isEmpty ? " x-add" : "";
  const isMediaField = acceptType === "media";
  const hasErrorClass = isEmpty && !allowClear ? " x-has-error" : "";
  const hasVerticalIcon = fileType === "inlineVideo" || fileType === "image";
  const containerClasses = arrayToString([
    "x-document-with-upload",
    !url ? "x-empty-uploader" : undefined,
  ]);

  return (
    <div className={containerClasses}>
      {file ? (
        <MediaFile file={file} isClickable={!isPendingUpload(url)} />
      ) : undefined}
      <div className={hasVerticalIcon ? "" : "x-flex"}>
        <Uploader
          uploadFile={onUpload}
          buttonAsIcon={!isEmpty}
          isNewFile={isEmpty}
          iconAddNewClass={"fa-upload"}
          acceptType={acceptType ?? "all"}
          buttonText={uploaderLabel ?? _("Upload document")}
          className={`x-document-uploader${addClass}${hasErrorClass}`}
        />
        {!isEmpty && (isMediaField || allowClear) ? (
          <div className="x-btn-delete-wrapper" onClick={onRemove}>
            <i className="fa fa-trash" />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
