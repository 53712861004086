import { JSX } from "react";
import { StringObject } from "common/app/query-string";
import { ImageWithLoading } from "common/widgets/image-with-loading";
import { InjectedProps, toggleable } from "common/widgets/toggleable";
import { FullPageModal } from "../full-page-modal";
import { getImageSource } from "./functions";

interface PropTypes {
  value: string;
  className?: string;
  alt?: string;
  allowZoom?: boolean;
  errorComp?: JSX.Element;
  onError?: () => void;
  srcParameters?: StringObject;
}

const create = (displayName: string, style: string) => {
  const image = (props: PropTypes & InjectedProps) => {
    const {
      value,
      className = "",
      alt,
      allowZoom,
      toggleOpened,
      toggleFn,
      errorComp,
      onError,
      srcParameters,
    } = props;
    const withZoom = allowZoom ? "x-image-with-zoom" : "";

    const imgComp = value ? (
      <ImageWithLoading
        src={getImageSource(value, srcParameters)}
        alt={alt}
        errorComp={errorComp || <i className="fa fa-file-excel-o" />}
        onError={onError}
        onClick={allowZoom && toggleFn}
      />
    ) : (
      <i className="fa fa-file-image-o" />
    );

    return (
      <div className={`${style} ${className} ${withZoom}`}>
        {imgComp}
        {allowZoom && toggleOpened && (
          <div className="x-modal-image-preview">
            <FullPageModal closeButtonClick={toggleFn} backdropClose={toggleFn}>
              {imgComp}
            </FullPageModal>
          </div>
        )}
      </div>
    );
  };
  image.displayName = displayName;
  return image;
};

export const ImageSmall = toggleable({ defaultOpened: false })(
  create("ImageSmall", "x-image-small"),
);
export const ImageSmallTable = toggleable({ defaultOpened: false })(
  create("ImageSmall", "x-image-small-table"),
);
export const Image = toggleable({ defaultOpened: false })(
  create("Image", "x-image"),
);
export const ImageLarge = toggleable({ defaultOpened: false })(
  create("ImageLarge", "x-image-large"),
);
