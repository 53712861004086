import { Context } from "common/types/context";
import { MenuEntrySetting, Preferences } from "common/types/preferences";
import { ValueProps } from "common/with-value-for";
import { NavigationConfigController } from "x/layout/navigation/config";

// import Themes from './themes';

interface PropTypes extends ValueProps<Preferences> {
  context: Context;
}

export const PreferencesForm = ({ context, value, onChange }: PropTypes) => {
  const onChangeMenu = (menu: MenuEntrySetting[]) => {
    onChange({ ...value, menu });
  };

  return (
    <div className="x-user-profile-preferences form-horizontal x-section">
      <div className="x-user-profile-preferences-menuConfig">
        <fieldset className="x-section-group">
          <legend className="x-legend">{_("Left menu items")}</legend>
          <NavigationConfigController
            context={context}
            value={value?.menu}
            onChange={onChangeMenu}
          />
        </fieldset>
      </div>
      {/* temporary solution until dark theme will back
        <div className="x-user-profile-preferences-themes">
          <legend className="x-legend">{_("Themes")}</legend>
          <Themes value={value.theme} onChange={onChangeMergeValue("theme")} />
        </div> */}
    </div>
  );
};
PreferencesForm.displayName = "PreferencesForm";
