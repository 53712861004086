import { schedulerSettingsApi } from "common/api/scheduler-settings";
import { searchApi } from "common/api/search";
import type { Entity } from "common/entities/types";
import type { Context } from "common/types/context";
import type { SchedulerSettingsType } from "common/types/scheduler-settings";
import type { SchedulerApiData, UserContact } from "x/scheduler2/types";

const contactQuery = (entity: Entity, userName: string) => ({
  entity: entity.name,
  query: {
    ...entity.query,
    select: [{ name: "id" }, { name: "email" }],
    filter: {
      and: [
        { name: "isDeleted", op: "isfalse" },
        { name: "email", op: "eq", value: userName },
      ],
    },
  },
});

export const getSchedulerApiData = (context: Context) => {
  const { apiCall, entities, userName } = context;

  return schedulerSettingsApi(apiCall)
    .get()
    .then((schedulerSettings: SchedulerSettingsType) => {
      const entityName = schedulerSettings?.entityName;

      if (!entityName) return { schedulerSettings, userContact: undefined };

      return searchApi(apiCall)
        .runQuery(contactQuery(entities[entityName], userName))
        .then((userContact: UserContact[]) => ({
          schedulerSettings,
          userContact: userContact[0],
        }));
    });
};

export const dependenciesInjected: SchedulerApiData = {
  schedulerApiData: {
    schedulerSettings: {
      entityName: undefined,
      groupBy: undefined,
    },
    userContact: undefined,
  },
};
