import { ESignatureReason } from "common/explicit-auth/esignature-reason";
import { RecoverLink } from "common/explicit-auth/forgot-password";
import { PasswordRow } from "common/explicit-auth/password-row";
import { useResetEmail } from "common/explicit-auth/use-reset-email";
import { ESignatureSettings } from "common/types/esignature-settings";
import { ESignature } from "common/types/reasons";
import { Context } from "common/types/context";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";

import "./index.scss";

interface Props {
  context: Context;
  submit: () => void;
  error: ApiErrorResponse;
  loading: boolean;
  loadingError: any;
  password: string;
  onPasswordChange: (password: string) => void;
  eSignatureSettings: ESignatureSettings;
  reason: ESignature;
  onReasonChange: (reason: ESignature) => void;
  hideReason?: boolean;
  onErrorChange?: (error: ApiErrorResponse) => void;
}

export const ESignatureContext = ({
  context,
  submit,
  error,
  loading,
  loadingError,
  password,
  onPasswordChange,
  eSignatureSettings,
  reason,
  onReasonChange,
  hideReason = false,
  onErrorChange,
}: Props) => {
  const { resetPasswordError, isSent, onSendClick } = useResetEmail(
    context,
    onErrorChange,
  );
  if (loading) return <LoadingIcon />;

  const errorToDisplay = error ?? loadingError ?? resetPasswordError;

  return (
    <>
      {errorToDisplay && (
        <ApiError error={errorToDisplay} className="x-margin-bottom-10-i" />
      )}
      <div className="x-wrapper-esignature-context">
        <PasswordRow
          focus={true}
          value={password}
          onChange={onPasswordChange}
          onSubmit={submit}
        />
        <RecoverLink onSendClick={onSendClick} isSent={isSent} />
        {hideReason ? undefined : (
          <ESignatureReason
            context={context}
            eSignatureSettings={eSignatureSettings}
            reason={reason}
            onReasonChange={onReasonChange}
          />
        )}
      </div>
    </>
  );
};
