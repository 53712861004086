import debounce from "lodash-es/debounce";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { createQueryString } from "common/app/query-string";
import { splitPath } from "common/app/router";
import { Updater } from "common/app/updater";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { GoFn, QueryString } from "common/types/url";
import { arrayToString } from "common/utils/array";
import { ValueComponent } from "common/with-value-for";
import { AnnouncementsController } from "x/announcements/announcements-controller";
import { ExplorerPanelController } from "./explorer-panel";
import { Header, HeaderValue } from "./header";
import { HistoryPanelController } from "./history-panel";
import { MenuPanel } from "./navigation/menu-panel";
import { Sla, SlaValue } from "./sla";

export interface LayoutValue {
  avatar: string;
  lastNonAdminPath: string;
  // TODO: RTS, don't use the query to find the entity
  query: QueryForEntity;
  seenAnnouncements: number[];
  menuOpen: boolean;
  disableAutoMenuToggle: boolean;
  siteOpen: boolean;
  historyOpen: boolean;
  explorerOpen: boolean;
  sla?: SlaValue;
}

interface PropTypes {
  context: Context;
  path: string;
  page: string;
  id: string | number;
  extra: string[];
  queryString: QueryString;
  goTo: GoFn;
  onLogout: () => void;
  reloadUi: () => CancellablePromise<any>;
  children?: any;
}

const changeSite = (
  path: string,
  queryString: QueryString,
  newSite?: string,
) => {
  const qs = createQueryString(queryString);
  const [site, ...rest] = splitPath(path);
  return [newSite || site, ...rest, qs].filter((s) => !!s).join("/");
};

const removeIdFromPath = (path: string) =>
  splitPath(path).slice(0, 2).join("/"); // We assume path is site/page/id/...

const getExplorerExtity = (path: string, context: Context) => {
  const pathParts = path.split("/");
  const page = pathParts[0] === "" ? pathParts[2] : pathParts[1];
  return context.entities[page] ? page : undefined;
};

export class Layout extends ValueComponent<LayoutValue, PropTypes> {
  static readonly displayName = "Layout";
  onWindowResize = debounce(() => this.toggleMenu(), 100);

  componentDidMount() {
    this.toggleMenu();
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  toggleMenu = () => {
    const autoDisabled = this.props?.value?.disableAutoMenuToggle;
    if (!autoDisabled) this.mergeValue1("menuOpen", window.innerWidth > 543);
  };

  onChangeHeader = (newValue: HeaderValue) => {
    const { context, page, path, value, queryString, goTo } = this.props;
    const { query } = value;
    const {
      avatar,
      site,
      siteOpen,
      menuOpen,
      historyOpen,
      explorerOpen,
      disableAutoMenuToggle,
    } = newValue;
    this.mergeValue({
      avatar,
      siteOpen,
      menuOpen,
      historyOpen,
      explorerOpen,
      disableAutoMenuToggle,
    });

    if (site === context.site.name) return;

    const entity = context.entities[(query && query.entity) || page];

    // When switching sites on admin page or for SharedAllSites entity, stay on the record view
    const newPath =
      path.indexOf("/admin/") !== -1 || entity?.recordScope === "SharedAllSites"
        ? path
        : removeIdFromPath(path);
    const newUrl = changeSite(newPath, queryString, site);
    goTo(newUrl);
  };

  getLayoutClasses = () => {
    const { context, queryString, path, value } = this.props;
    const { menuOpen, historyOpen, explorerOpen } = value;
    const explorerEntity = explorerOpen
      ? getExplorerExtity(path, context)
      : undefined;
    const { hidePanels } = queryString;

    return arrayToString([
      "x-layout",
      "x-modal-parent",
      hidePanels ? "x-hidden-panels" : undefined,
      menuOpen ? "x-menu-active" : "x-small-menu-active",
      explorerEntity ? "x-explorer-active" : undefined,
      historyOpen ? "x-history-active" : undefined,
    ]);
  };

  render() {
    const {
      context,
      page,
      queryString,
      path,
      children,
      onLogout,
      reloadUi,
      value,
      goTo,
    } = this.props;
    const {
      avatar,
      menuOpen,
      siteOpen,
      historyOpen,
      explorerOpen,
      lastNonAdminPath,
      sla,
      disableAutoMenuToggle,
    } = value;

    if (
      !context.isSystem &&
      !context.isImpersonated &&
      !context.tenant.slaAccepted
    ) {
      return (
        <Sla
          apiCall={context.apiCall}
          reloadUi={reloadUi}
          value={sla}
          onChange={this.onChangeMergeValue("sla")}
        />
      );
    }

    const { site, apiCall } = context;

    const explorerEntity = explorerOpen
      ? getExplorerExtity(path, context)
      : undefined;

    const { hidePanels, url } = queryString;
    const isAdmin = page === "admin";

    const location = path.indexOf("notfound") !== -1 && url ? url : path;

    return (
      <div className={this.getLayoutClasses()}>
        {!hidePanels ? (
          <Header
            context={context}
            goTo={goTo}
            logout={onLogout}
            isAdmin={isAdmin}
            announcementsActive={page === "Announcements"}
            value={{
              avatar,
              site: site.name,
              menuOpen,
              disableAutoMenuToggle,
              siteOpen,
              historyOpen,
              explorerOpen,
            }}
            onChange={this.onChangeHeader}
          />
        ) : undefined}

        <div className="x-main">{children}</div>

        {!hidePanels && historyOpen ? (
          <HistoryPanelController context={context} />
        ) : undefined}

        {!hidePanels ? (
          <MenuPanel
            context={context}
            isAdmin={isAdmin}
            lastNonAdminPath={lastNonAdminPath}
            location={location}
            explorerEntity={explorerEntity}
            explorerOpen={explorerOpen}
          />
        ) : undefined}

        {!hidePanels && explorerEntity ? (
          <ExplorerPanelController
            context={context}
            entityName={explorerEntity}
          />
        ) : undefined}

        {context.announcements ? (
          <AnnouncementsController context={context} />
        ) : undefined}

        <Tooltip id="x5-tooltip" place="top" variant="info" />
        <Updater apiCall={apiCall} contextAppVersion={context.version} />
        <ToastContainer theme={"light"} />
      </div>
    );
  }
}
