import * as R from "ramda";
import { getStartOfToday } from "common/date-time/calculators";
import { merge2 } from "common/merge";
import type { Context } from "common/types/context";
import type { Preferences, SchedulerSettings } from "common/types/preferences";
import { CancellablePromise } from "common/types/promises";
import {
  filterValueToFk,
  fkToFilterValue,
} from "x/scheduler2/planner/header/filter/filter-value";
import type { SchedulerData, UnassignedWorkData } from "x/scheduler2/types";
import {
  translateViewForPreferences,
  translateViewFromPreferences,
} from "./shared";

export const getSchedulerData = (context: Context): SchedulerData => {
  const allPreferences = context.preferenceService.get();
  const { defaultView, defaultSelectedDate, defaultWeekends, defaultFilter } =
    allPreferences?.scheduler ?? {};
  const translatedDefaultView = translateViewFromPreferences(defaultView);

  return {
    view: translatedDefaultView ?? "week",
    selectedDate: defaultSelectedDate ?? getStartOfToday(),
    hideWeekends: defaultWeekends ?? false,
    filterValue: fkToFilterValue(defaultFilter),
  };
};

export const getUnassignedWorkOrdersData = (
  context: Context,
): UnassignedWorkData => {
  const allPreferences = context.preferenceService.get();
  const { defaultWOSearch, defaultWOQueries } = allPreferences?.scheduler ?? {};

  return {
    searchValue: defaultWOSearch?.value,
    customQueries: defaultWOQueries,
  };
};

export const setSchedulerPreferences = (
  context: Context,
  newValue: SchedulerData,
) => {
  const allPreferences = context.preferenceService.get();
  const oldValue = allPreferences.scheduler;

  const newPreferences: Preferences = {
    ...allPreferences,
    scheduler: {
      ...allPreferences.scheduler,
      defaultView:
        translateViewForPreferences(newValue.view) ?? oldValue?.defaultView,
      defaultSelectedDate:
        newValue.selectedDate ?? oldValue?.defaultSelectedDate,
      defaultWeekends: newValue.hideWeekends ?? oldValue?.defaultWeekends,
      defaultFilter: newValue.filterValue
        ? filterValueToFk(newValue.filterValue)
        : oldValue?.defaultFilter,
    },
  };

  return R.equals(allPreferences, newPreferences)
    ? CancellablePromise.resolve()
    : context.preferenceService.set(newPreferences);
};

export const setDefaultPreferences = <K extends keyof SchedulerSettings>(
  context: Context,
  key: K,
  value: SchedulerSettings[K],
) => {
  const allPreferences = context.preferenceService.get();
  const newPreferences = merge2("scheduler", key, value, allPreferences);
  if (!R.equals(allPreferences, newPreferences)) {
    return context.preferenceService.set(newPreferences);
  }
  return CancellablePromise.resolve();
};
