import * as R from "ramda";
import {
  adminAndFlukeMobileUserType,
  adminUserType,
  defaultUserType,
  defaultUserTypes,
  FilterableUser,
  flukeMobileUserType,
  flukeMobileUserTypes,
  fullUserTypes,
  requestorUserType,
  User,
  userAndFlukeMobileUserType,
  UserType,
  vendorUserType,
} from "common/functions/users";
import { Context } from "common/types/context";

export const displayUser = (user: User) => {
  return user?.fullName || user?.userName || "";
};

export const isUserEnabled = (user: User) => !!user?.enabled;
export const isUserDisabled = (user: User) => !user?.enabled;

export const isSameUserType = (ut1: UserType[]) => (ut2: UserType[]) =>
  !!ut1 && !!ut2 && !R.symmetricDifference(ut1, ut2).length;

export const translateUserType = (userTypes: UserType[]) => {
  const isSame = isSameUserType(userTypes);
  if (isSame(defaultUserType)) return _("User");
  if (isSame(adminUserType)) return _("Admin");
  if (isSame(requestorUserType)) return _("Requestor");
  if (isSame(vendorUserType)) return _("Technician (Limited Access)");
  if (isSame(flukeMobileUserType)) return _("Fluke Mobile Only");
  if (isSame(userAndFlukeMobileUserType)) return _("User and Fluke Mobile");
  if (isSame(adminAndFlukeMobileUserType)) return _("Admin and Fluke Mobile");
  return "";
};

export const convertUsersToFilterableUsers = (
  context: Context,
  users: User[],
): FilterableUser[] =>
  (users ?? []).map(
    ({
      id,
      userName,
      fullName,
      role,
      siteName,
      uiFormat,
      enabled,
      userTypes = defaultUserType,
    }) => ({
      id,
      userName,
      fullName,
      enabled,
      siteName,
      roleId: role.id,
      roleLabel: role.name,
      siteLabel: context.getSite(siteName).label,
      language: context.supportedCultures.find(
        (c) => c.name === uiFormat.culture,
      ).language,
      userType: translateUserType(userTypes),
    }),
  );

export const getAllUserTypes = (context: Context): UserType[][] => {
  const fmUserTypes = context.isEnabledFeature("flukeMobile")
    ? flukeMobileUserTypes
    : [];

  const vendorPortalUserTypes = context.isEnabledFeature("vendorPortal")
    ? [vendorUserType]
    : [];

  return defaultUserTypes.concat(fmUserTypes).concat(vendorPortalUserTypes);
};

export const isAdminUserType = (userTypes: UserType[] = []) =>
  userTypes.includes("Admin");

export const isRequestorUserType = isSameUserType(requestorUserType);

export const isFlukeMobileUserType = isSameUserType(flukeMobileUserType);

export const isVendorUserType = isSameUserType(vendorUserType);

export const isFullUserType = (userTypes: UserType[] = []) =>
  fullUserTypes.some((x) => userTypes.includes(x));

export const isAdminUser = (user: User) => isAdminUserType(user?.userTypes);

export const isRequestorUser = (user: User) =>
  isRequestorUserType(user?.userTypes);

export const isVendorUser = (user: User) => isVendorUserType(user?.userTypes);

export const isFlukeMobileUser = (user: User) =>
  user?.userTypes.includes("FlukeMobile");

export const isUser = (user: User) =>
  user?.userTypes.includes("User") && !isAdminUser(user);

export const isFullUser = (user: User) =>
  user?.userTypes.includes("User") || isAdminUser(user);

export const getUserTypeOptions = (
  context: Context,
  formUserTypes: UserType[],
): UserType[][] => {
  const isUserTypeUser = context.userTypes.includes("User");

  return isAdminUserType(context.userTypes)
    ? getAllUserTypes(context)
    : isUserTypeUser && !isAdminUserType(formUserTypes)
      ? getAllUserTypes(context).filter((userTypes) =>
          userTypes.every((userType) => userType !== "Admin"),
        )
      : [formUserTypes];
};
