import { Component } from "react";
import InputSlider from "react-input-slider";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<number> {
  minValue?: number;
  maxValue: number;
}

interface Position {
  y: string;
  x: string;
}

export class TimeSlider extends Component<PropTypes> {
  static readonly displayName = "TimeSlider";

  onChangeTimeSlider = (pos: Position) => {
    this.props.onChange(parseInt(pos.x, 10));
  };

  render() {
    const { value, minValue = 0, maxValue } = this.props;
    return (
      <InputSlider
        className="x-time-slider"
        axis="x"
        xmin={minValue}
        xmax={maxValue}
        x={value}
        onChange={this.onChangeTimeSlider}
      />
    );
  }
}
