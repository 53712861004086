import { withValue, WithValue } from "common/with-value";
import { Checkbox } from "./checkbox";

interface PropTypes {
  label?: string;
  disabled?: boolean;
  className?: string;
  inputId?: string;
}

const CheckboxWidgetComp = ({
  label,
  disabled,
  className = "",
  inputId,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<boolean>) => {
  return (
    <label
      className={`x-checkbox ${className} ${disabled ? " x-disabled" : ""}`}
    >
      <Checkbox
        value={value}
        disabled={disabled}
        inputId={inputId}
        onChange={onChangeSetValue}
      />
      {label}
    </label>
  );
};

export const CheckboxWidget = withValue<boolean, PropTypes>(
  CheckboxWidgetComp,
  "CheckboxWidget",
);
