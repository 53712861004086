import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  iconClass?: string;
}

export class UserAvatar extends ValueComponent<string, PropTypes> {
  static readonly displayName = "UserAvatar";
  render() {
    const { iconClass = "", value } = this.props;
    return (
      <div className="x-user-avatar">
        {value ? (
          <img
            src={value}
            onError={this.onChangeSetDefaultValue(undefined)}
            alt={_("User Avatar")}
          />
        ) : (
          <i className={`fa fa-user ${iconClass}`} />
        )}
      </div>
    );
  }
}
