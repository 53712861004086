import * as R from "ramda";
import { Component, JSX } from "react";
import { defaultFor } from "common";
import { isReferenceEntity } from "common/api/entities";
import { Entity } from "common/entities/types";
import { FormFooter, LoadingButtonLabels } from "common/form/footer";
import { isFormValid } from "common/form/functions/validation";
import { keepOwnCultureFirst } from "common/form/reference-group/functions";
import { FormValidation, Layout } from "common/form/types";
import { merge2 } from "common/merge";
import { RunQuery } from "common/query/types";
import { Content } from "common/record/form/content";
import {
  hasPendingChanges,
  isDelete,
  onFormSave,
  retrieveCommentsCount,
} from "common/record/form/functions";
import { isOverview } from "common/record/utils";
import { RequestOptions } from "common/types/api";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { ValueProps } from "common/with-value-for";
import { RecordHeader } from "../header";
import { auditTrail, generalInfo, Sidebar } from "../sidebar";
import { SidebarElement } from "../sidebar/types";
import { Reload, StandardUiValue, StandardValue } from "../types";
import { ReadOnlyReports } from "./content/detail/view/reports";

type ExtraValidation = (
  context: Context,
  entity: Entity,
  value: StandardValue,
) => boolean;

interface PropTypes extends ValueProps<StandardValue> {
  context: Context;
  entity: Entity;
  withLinks: boolean;
  runQuery: RunQuery;
  reload: Reload;
  auditTrailId: string;
  layout: Layout;
  saving: boolean;
  loading?: boolean;
  disableEdit?: boolean;
  disableReason?: string;
  deleteLabels?: LoadingButtonLabels;
  saveLabels?: LoadingButtonLabels;
  displaySidebar?: boolean;
  save: (
    record: Record,
    confirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => CancellablePromise<any>;
  onCancel: () => any;
  onHasChanged?: (isDirty: boolean) => any;
  onDelete?: () => void;
  extraValidation?: ExtraValidation;
  createExtraActionButton?: (isValid: boolean) => JSX.Element;
  goTo?: GoFn;
  getUrl?: (id: string | number) => string;
}

const defaultUiValue = defaultFor<StandardUiValue>();
const defaultRecord = defaultFor<Record>();

interface StateType {
  formValidation: FormValidation;
  commentsCount: number;
}

export class RecordContentForm extends Component<PropTypes, StateType> {
  static readonly displayName = "RecordContentForm";
  state: StateType = {
    formValidation: undefined,
    commentsCount: 0,
  };
  fetchCommentsCountRequest: CancellablePromise<unknown>;

  componentDidMount() {
    const { context, entity, value } = this.props;

    this.fetchCommentsCountRequest = retrieveCommentsCount({
      context,
      entity,
      value,
      updateCommentsCount: this.updateCommentsCount,
    });
  }

  componentWillUnmount() {
    this.fetchCommentsCountRequest?.cancel();
  }

  onFormValidationChange = (formValidation: FormValidation) => {
    this.setState({ formValidation });
  };

  updateCommentsCount = (count: number) => {
    this.setState({ commentsCount: count ?? 0 });
  };

  onChangeContent = (newValue: StandardValue) => {
    const { save, reload, onCancel, onHasChanged, value, onChange } =
      this.props;
    const oldRecord = value.record;
    const newRecord = newValue.record;
    const newUi = newValue.ui;

    if (!R.equals(oldRecord, newRecord)) {
      // if the record change, we must SAVE!
      // TODO: we could
      //   .then(() => { this.setValue(e.target.value); reload(); })
      // when adding related, otherwise just do a
      //   .then(() => this.setValue(e.target.value))
      save(newRecord, isDelete(newRecord)).then(() => {
        onChange(newValue);
        if (onHasChanged) {
          onHasChanged(false); // reset dirty flag
        }
        reload();
      });
      return;
    }

    if (!newRecord.properties.id && !newUi?.detail?.form) {
      onCancel();
      return;
    }

    if (
      // if editing related, allow editing detail as well
      newUi?.related?.isDirty &&
      !newUi?.detail?.form
    ) {
      onChange(
        R.set(
          R.lensPath(["ui", "detail", "form"]),
          newRecord.properties,
          newValue,
        ),
      );
    } else {
      onChange(newValue);
      if (onHasChanged) {
        onHasChanged(hasPendingChanges(newUi));
      }
    }

    if (onHasChanged && hasPendingChanges(newUi)) {
      onHasChanged(true);
    }
  };

  onChangeHeader = (properties: Properties) => {
    const { onHasChanged, value, onChange } = this.props;
    const { ui = defaultUiValue } = value;

    onChange(merge2("record", "properties", properties, value));
    onHasChanged(hasPendingChanges(ui));
  };

  onChangeSidebar = (sidebar: SidebarElement) => {
    const { value, onChange } = this.props;

    onChange(merge2("ui", "sidebar", sidebar, value));
  };

  getContent = (
    editing: boolean,
    layout: Layout,
    content: StandardValue,
    classColName: string,
  ) => {
    const {
      context,
      entity,
      runQuery,
      auditTrailId,
      reload,
      saving,
      onCancel,
      withLinks,
      onDelete,
      deleteLabels,
      saveLabels,
      disableEdit,
      goTo,
      value,
      extraValidation = R.T,
      createExtraActionButton,
      onHasChanged,
      save,
      onChange,
      getUrl,
    } = this.props;
    const { formValidation } = this.state;

    const isValid =
      isFormValid(entity, layout, formValidation, value?.ui?.detail?.form) &&
      extraValidation(context, entity, value);

    const onSave = () =>
      onFormSave({ value, save, onHasChanged, reload, onChange });

    return (
      <div className={`${classColName} x-form-content-wrapper`}>
        <Content
          context={context}
          runQuery={runQuery}
          entity={entity}
          layout={layout}
          withLinks={withLinks}
          auditTrailId={auditTrailId}
          reload={reload}
          saving={saving}
          updateRecordOnChange={false}
          disableEdit={disableEdit}
          formValidation={formValidation}
          onFormValidationChange={this.onFormValidationChange}
          goTo={goTo}
          onCommentsCountChange={this.updateCommentsCount}
          value={content}
          onChange={this.onChangeContent}
          getUrl={getUrl}
        />
        {editing && (
          <FormFooter
            isNew={!value?.record?.properties?.id}
            isValid={isValid}
            canCancel={true}
            canSave={true}
            onSave={onSave}
            onCancel={onCancel}
            isSaving={saving}
            canDelete={true}
            onDelete={onDelete}
            isDeleting={false}
            canRestore={false}
            onRestore={undefined}
            isRestoring={false}
            deleteLabels={deleteLabels}
            saveLabels={saveLabels}
            extraActionButton={createExtraActionButton?.(isValid)}
          />
        )}
      </div>
    );
  };

  getLayout = () => {
    const { context, entity, layout } = this.props;

    if (!isReferenceEntity(entity)) return layout;

    // reorder and require current lang
    const ownCulture = context.uiFormat.culture;
    const sortedColumns = keepOwnCultureFirst(
      layout.groups[0].columns,
      ownCulture,
      { required: true },
    );
    return {
      ...layout,
      groups: [{ ...layout.groups[0], columns: sortedColumns }],
    };
  };

  render() {
    const {
      context,
      disableEdit,
      disableReason,
      entity,
      runQuery,
      layout,
      loading,
      withLinks,
      value,
      auditTrailId,
      displaySidebar = true,
    } = this.props;

    if (loading) return null;

    const { onlyGroups } = layout;
    const { record = defaultRecord, ui = defaultUiValue } = value;
    const { properties } = record;

    const defaultSidebar =
      auditTrailId && properties.id ? auditTrail : generalInfo;

    const content = !ui.sidebar // if no sidebar, inject default into value
      ? { ...value, ui: { ...ui, sidebar: defaultSidebar } }
      : value;

    const editing = !!(ui.detail?.form || (ui.related && ui.related.isDirty));

    const classCol = displaySidebar ? "col-lg-9" : "col-lg-12";

    const stats =
      isOverview(content.ui.sidebar.label) &&
      !content.ui.detail?.form &&
      layout.reports?.section?.length ? (
        <div className={`${classCol} x-record-stats`}>
          <ReadOnlyReports
            entityName={entity.name}
            context={context}
            runQuery={runQuery}
            reports={layout.reports.section}
          />
        </div>
      ) : null;

    if (onlyGroups) {
      return this.getContent(
        editing,
        this.getLayout() || layout,
        content,
        classCol,
      );
    }

    return (
      <div className="x-padding-20">
        <RecordHeader
          context={context}
          entity={entity}
          layout={layout.header}
          withLinks={withLinks}
          disableEdit={disableEdit}
          disableReason={disableReason}
          value={properties}
          onChange={this.onChangeHeader}
        />
        <hr />
        <div className="row x-form-content-container">
          {displaySidebar && (
            <div className="col-lg-3 x-form-content-sidebar">
              <Sidebar
                context={context}
                layout={layout}
                entity={entity}
                record={record}
                ui={ui}
                disabled={false}
                editing={editing}
                commentsCount={this.state.commentsCount}
                value={content.ui.sidebar}
                onChange={this.onChangeSidebar}
              />
            </div>
          )}
          {stats}
          {this.getContent(
            editing,
            this.getLayout() || layout,
            content,
            classCol,
          )}
        </div>
      </div>
    );
  }
}
