import * as React from "react";
import { ChangeEvent } from "react";
import { FileType } from "common/types/media";
import { getAllowedExtensionsAsString } from "./functions";
import { AcceptType } from "./types";

interface PropTypes {
  onFileSelect: (file: FileType) => any;
  buttonAsIcon: boolean;
  iconClass: string;
  acceptType: AcceptType;
  className?: string;
  buttonText?: string;
}

export class FileSelector extends React.Component<PropTypes> {
  static readonly displayName = "FileSelector";
  fileRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    this.props.onFileSelect(file);
    this.fileRef.current.value = "";
  };

  render() {
    const {
      buttonAsIcon,
      buttonText,
      acceptType,
      className = "",
      iconClass = "",
    } = this.props;

    const allowedExtensions = getAllowedExtensionsAsString(acceptType);

    if (!buttonAsIcon) {
      return (
        <div
          className={`x-button-action-large x-file-upload-btn qa-file-upload-btn ${className}`}
        >
          {iconClass.length ? <i className={`fa ${iconClass}`} /> : undefined}
          {buttonText || _("Choose File")}
          <input
            ref={this.fileRef}
            type="file"
            onChange={this.onChange}
            accept={allowedExtensions}
          />
        </div>
      );
    }

    return (
      <div className={`x-uploader qa-uploader ${className}`}>
        <div className="x-fields-upload-wrapper">
          <label>
            <input
              ref={this.fileRef}
              type="file"
              onChange={this.onChange}
              accept={allowedExtensions}
            />
          </label>
        </div>
        {iconClass.length ? (
          <div className="x-btn-upload-wrapper qa-btn-upload-wrapper">
            <i className={`fa ${iconClass}`} />
          </div>
        ) : undefined}
      </div>
    );
  }
}
