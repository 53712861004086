import debounce from "lodash-es/debounce";
import { Component, ComponentClass, ComponentType } from "react";
import { ValueProps } from "common/with-value-for";
import { Cancelable } from "common/types/debounce";

const DEFAULT_DEBOUNCE_TIMEOUT = 250;

export type WithDebounceProps<TOriginalProps> = TOriginalProps & {
  debounceTimeout?: number;
};

interface State<TValue> {
  value: TValue;
}

/**
 * Higher Order Proxy Component providing debounce on value change
 */
export const withDebounce = <TValue, TOriginalProps extends ValueProps<TValue>>(
  YourComponent: ComponentType<TOriginalProps>,
): ComponentClass<WithDebounceProps<TOriginalProps>, State<TValue>> => {
  type ResultProps = WithDebounceProps<TOriginalProps>;

  return class DebounceValue extends Component<ResultProps, State<TValue>> {
    static readonly displayName = "DebounceValue";
    isDebouncing: boolean = false;
    propagateChangeDelayed: ((value: TValue) => void) & Cancelable;

    constructor(props: ResultProps) {
      super(props);
      this.state = { value: props.value };
      this.createDelayedPropagation(
        this.props.debounceTimeout ?? DEFAULT_DEBOUNCE_TIMEOUT,
      );
    }

    componentDidUpdate(prevProps: ResultProps) {
      if (this.isDebouncing) return;

      const { value, debounceTimeout } = this.props;

      if (prevProps.value !== value && this.state.value !== value) {
        this.setState({ value });
      }

      if (prevProps.debounceTimeout !== debounceTimeout) {
        this.createDelayedPropagation(debounceTimeout);
      }
    }

    componentWillUnmount() {
      this.propagateChangeDelayed?.cancel();
    }

    createDelayedPropagation = (debounceTimeout: number) => {
      this.propagateChangeDelayed = debounce((value: TValue) => {
        this.isDebouncing = false;
        this.propagateChange(value);
      }, debounceTimeout);
    };

    propagateChange = (value: TValue) => {
      this.props.onChange(value);
    };

    onChildChange = (value: TValue) => {
      this.isDebouncing = true;
      this.setState({ value }, () => {
        this.propagateChangeDelayed?.(value);
      });
    };

    render() {
      return (
        <YourComponent
          {...this.props}
          value={this.state.value}
          onChange={this.onChildChange}
        />
      );
    }
  };
};
