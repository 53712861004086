import { TimeRangeModelConfig } from "@bryntum/calendar";
import type { EventModelConfig } from "@bryntum/scheduler";
import { transferSitedToLocal } from "common/date-time/helpers/scheduler-bryntum";
import { isNotWorkingEvent } from "common/functions/scheduler";
import type { ContactEvents, SchedulerEvent } from "common/types/scheduler";
import type { ResourceTimeRange, TimeRange } from "x/scheduler2/types";

const mapResourceTimeRange =
  (resourceId: string) =>
  ({ rangeFrom, rangeTo }: SchedulerEvent): ResourceTimeRange => ({
    resourceId,
    startDate: transferSitedToLocal(rangeFrom),
    endDate: transferSitedToLocal(rangeTo),
    cls: "x-non-working-hours",
  });

export const getResourceTimeRanges = (
  records: ReadonlyArray<ContactEvents>,
): ResourceTimeRange[] =>
  records.reduce(
    (acc: Partial<EventModelConfig>[], record: ContactEvents) =>
      acc.concat(
        record.events
          .filter((e) => isNotWorkingEvent(e))
          .map(mapResourceTimeRange(record.properties.id)),
      ),
    [],
  );

const mapTimeRange = ({ rangeFrom, rangeTo }: SchedulerEvent): TimeRange => ({
  startDate: transferSitedToLocal(rangeFrom),
  endDate: transferSitedToLocal(rangeTo),
  cls: "x-non-working-hours",
});

export const getTimeRanges = (
  records: ReadonlyArray<ContactEvents>,
): TimeRange[] =>
  records.reduce(
    (acc: Partial<TimeRangeModelConfig>[], record: ContactEvents) =>
      acc.concat(
        record.events
          .filter((event) => isNotWorkingEvent(event))
          .map(mapTimeRange),
      ),
    [],
  );
