interface PropTypes {
  message?: string;
}

export const LoadingIcon = ({ message }: PropTypes) => (
  <div className="x-loading-box" data-testid="qa-loading-icon">
    <i
      className="fa fa-spinner fa-spin fa-3x x-loading-icon"
      data-testid="loading-icon"
    />
    {message ? <div className="x-loading-message">{message}</div> : undefined}
  </div>
);

LoadingIcon.displayName = "LoadingIcon";
