import { Context } from "common/types/context";
import { isAdminUserType } from "common/types/users";
import { CancelButton, LinkButton } from "common/ui/buttons";
import { Link } from "common/widgets/link";
import { UserAvatar } from "common/widgets/user-avatar";
import { withValue, WithValue } from "common/with-value";
import { FlukeMobileSettings } from "x/layout/header/fluke-mobile-settings";
import { SandboxResetForm } from "./sandbox-reset-form";

interface PropTypes {
  context: Context;
  site: string;
  logout: () => any;
}

export const UserPopover = withValue<string, PropTypes>(
  ({
    context,
    logout,
    site,
    value,
    onChangeSetValue,
  }: PropTypes & WithValue<string>) => {
    const { name, role, userName, isEnabledFeature, userTypes, tenant } =
      context;
    return (
      <div className="x-popover dropdown">
        <div className="x-logout-name x-flex hidden-xs" title={name}>
          <div className="x-user-name">{name}</div>
          <div className="x-user-role">{role?.name}</div>
        </div>
        <a
          data-bs-toggle="dropdown"
          className="x-header-button x-menu-user qa-menu-user"
          title={`${_("Account Settings")} / ${_("Log out")}`}
          tabIndex={0}
          role="button"
        >
          <UserAvatar value={value} onChange={onChangeSetValue} />
        </a>
        <div className="x-dropdown-form dropdown-menu">
          <div className="x-content">
            <div className="x-image-wrapper">
              <UserAvatar
                iconClass="fa-4x"
                value={value}
                onChange={onChangeSetValue}
              />
            </div>
            <div className="x-account">
              <span>{name}</span>
              <span>{userName}</span>
              <Link href={`/${site}/profile`} className="x-button-cancel-small">
                {_("Profile Settings")}
              </Link>
            </div>
          </div>
          <footer>
            <LinkButton
              href={`/${site}/admin`}
              className="qa-button-account-settings"
            >
              {_("Account Settings")}
            </LinkButton>
            {isAdminUserType(userTypes) && tenant?.parentTenantName && (
              <SandboxResetForm context={context} />
            )}
            {isEnabledFeature("flukeMobile") && isAdminUserType(userTypes) && (
              <FlukeMobileSettings context={context} />
            )}
            <CancelButton className="qa-btn-logout" onClick={logout}>
              <i className="fa fa-sign-out" />
              {_("Log out")}
            </CancelButton>
          </footer>
        </div>
      </div>
    );
  },
  "UserPopover",
);
