import type { ViewPresetBased } from "x/scheduler2/wrappers/scheduler";

export const plannerPresets: Partial<ViewPresetBased>[] = [
  {
    id: "day",
    base: "hourAndDay",
    headers: [
      {
        unit: "hour",
        increment: 1,
        dateFormat: "hA",
      },
    ],
    timeResolution: {
      unit: "minute",
      increment: 30,
    },
  },
  {
    id: "week",
    base: "weekAndDay",
    headers: [
      {
        unit: "day",
        increment: 1,
        dateFormat: "ddd DD",
      },
    ],
    timeResolution: {
      unit: "minute",
      increment: 30,
    },
  },
];
