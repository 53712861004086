import { Component } from "react";
import { ValueProps } from "common/with-value-for";
import { ActionButton, CancelButton } from "common/ui/buttons";

export type Page = number | "all";

interface PropTypes extends ValueProps<Page> {
  itemsPerPage: number;
  totalItems: number;
  allowShowAll?: boolean;
}

export const showAll = (page: Page): page is "all" => page === "all";

export class SimplePagination extends Component<PropTypes> {
  changePage = (pageNumber: number) => {
    const { value, onChange } = this.props;
    if (showAll(value)) return;
    onChange(value + pageNumber);
  };

  onPrevPage = () => {
    this.changePage(-1);
  };

  onNextPage = () => {
    this.changePage(1);
  };

  onViewPagesClick = () => {
    this.props.onChange(0);
  };

  onViewAllPagesClick = () => {
    this.props.onChange("all");
  };

  render() {
    const {
      allowShowAll = true,
      totalItems = 0,
      itemsPerPage = 0,
      value = 0,
    } = this.props;

    const lastPage = Math.ceil(totalItems / itemsPerPage) - 1 || 0;

    const showAllButtons = showAll(value) ? (
      <ActionButton
        className="qa-simple-pagination-page"
        onClick={this.onViewPagesClick}
      >
        <i className="fa fa-th-list x-padding-right-5" />
        {_("View pages")}
      </ActionButton>
    ) : (
      <ActionButton
        className="qa-simple-pagination-all"
        onClick={this.onViewAllPagesClick}
      >
        <i className="fa fa-th x-padding-right-5" />
        {_("View all")}
      </ActionButton>
    );

    return (
      <div className="x-simple-pagination x-flex-between-center">
        <div className="x-pagination-buttons">
          <CancelButton
            className="qa-simple-pagination-prev"
            onClick={this.onPrevPage}
            disabled={value === 0 || showAll(value)}
          >
            <i className="fa fa-chevron-left x-padding-right-5" />
            {_("Previous")}
          </CancelButton>
          {allowShowAll ? showAllButtons : undefined}
          <CancelButton
            className="qa-simple-pagination-next"
            onClick={this.onNextPage}
            disabled={value === lastPage || showAll(value)}
          >
            {_("Next")}
            <i className="fa fa-chevron-right x-padding-left-5" />
          </CancelButton>
        </div>
        {!showAll(value) ? (
          <p className="x-page-count">
            {value + 1} of {lastPage + 1}
          </p>
        ) : undefined}
      </div>
    );
  }
}
