import { Context } from "common/types/context";
import { getColumn } from "common/entities";
import { SummaryRecord } from "common/types/records";
import { LabelWidget } from "common/form/widget/label-widget";
import {
  getSelectFieldBySubSubtitle,
  getSelectFieldBySubtitle,
  getSelectFieldByTitle,
} from "common/query/select";

interface PropTypes {
  context: Context;
  record: SummaryRecord;
  entityName: string;
  filterKey: string;
}

export const SummaryRecordComponent = ({
  context,
  record,
  entityName,
  filterKey,
}: PropTypes) => {
  const { entities } = context;
  const entity = entities[entityName];
  const select = entity?.query?.select;

  const titleField = getSelectFieldByTitle(select);
  const subtitleField = getSelectFieldBySubtitle(select);
  const subsubtitleField = getSelectFieldBySubSubtitle(select);

  return (
    <div className="x-related-summary-record">
      <div className="x-related-title qa-title">
        <LabelWidget
          context={context}
          withLinks={true}
          column={getColumn(entity, titleField.name)}
          entityName={entityName}
          textToHighlight={filterKey}
          value={record.title}
        />
      </div>
      <div className="x-related-subtitle">
        <LabelWidget
          context={context}
          withLinks={true}
          column={subtitleField && getColumn(entity, subtitleField.name)}
          entityName={entityName}
          textToHighlight={filterKey}
          value={record.subtitle || record.id}
        />
      </div>
      <div className="x-related-subsubtitle">
        <LabelWidget
          context={context}
          withLinks={true}
          column={subsubtitleField && getColumn(entity, subsubtitleField.name)}
          entityName={entityName}
          textToHighlight={filterKey}
          value={record.subsubtitle || record.number}
        />
      </div>
    </div>
  );
};

SummaryRecordComponent.displayName = "SummaryRecordComponent";
