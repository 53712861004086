import { getUtcNow } from "common/date-time/common";
import { FORMAT_DATE_MONTH } from "common/date-time/formats";
import { formatDate } from "common/date-time/formatters";
import {
  formatRange,
  getNextDate,
  getPreviousDate,
} from "common/date-time/helpers/scheduler-bryntum";
import type { UTCDateTime } from "common/date-time/types";
import type { Context } from "common/types/context";
import { CancelButton } from "common/ui/buttons";
import { DateButtonModal } from "common/widgets/date/date-button-modal";
import { Label } from "common/widgets/label";
import { getWorkingDateRange } from "x/scheduler2/shared";
import { getTitleFormat } from "x/scheduler2/shared/date-selector/functions";
import type { ViewType } from "x/scheduler2/types";

interface PropTypes {
  context: Context;
  view: ViewType;
  date: UTCDateTime;
  hideWeekends: boolean;
  onDateChanged: (date: UTCDateTime) => void;
}

export const DateSelector = ({
  context,
  view,
  date,
  hideWeekends,
  onDateChanged,
}: PropTypes) => {
  const onPrevClicked = () => {
    onDateChanged(getPreviousDate(date, view));
  };

  const onNextClicked = () => {
    onDateChanged(getNextDate(date, view));
  };

  const onTodayClicked = () => {
    onDateChanged(getUtcNow());
  };

  const isToday = () => {
    const currentFormat = getTitleFormat(view);
    return (
      formatDate(date, currentFormat) === formatDate(getUtcNow(), currentFormat)
    );
  };

  const getTitle = () => {
    const { from, to } = getWorkingDateRange({
      view,
      selectedDate: date,
      hideWeekends,
    });
    return formatRange(from, to, view);
  };

  const isMonth = view === "month";
  const title = getTitle();

  return (
    <>
      <CancelButton
        title="today"
        className="x-scheduler-today qa-today"
        disabled={isToday()}
        onClick={onTodayClicked}
      >
        {_("Today")}
      </CancelButton>

      <CancelButton
        className="qa-prev"
        title={_("prev")}
        onClick={onPrevClicked}
      >
        <i className="fa fa-chevron-left" />
      </CancelButton>

      <CancelButton
        className="qa-next"
        title={_("next")}
        onClick={onNextClicked}
      >
        <i className="fa fa-chevron-right" />
      </CancelButton>

      <DateButtonModal
        culture={context.uiFormat.culture}
        dateFormat={isMonth ? FORMAT_DATE_MONTH : context.uiFormat.dateFormat}
        onChange={onDateChanged}
        value={date}
        viewMode={isMonth ? "months" : "days"}
        className="x-date-button-modal"
      >
        <i className="fa fa-calendar-o" />
        <Label title={title} value={title} />
      </DateButtonModal>
    </>
  );
};
