import * as R from "ramda";
import { eventsApi } from "common/api/events";
import { Event } from "common/types/events";
import { Record } from "common/types/records";
import { Component } from "common/component";
import { Entity } from "common/entities/types";
import { merge1 } from "common/merge";
import { AuditTrail } from "common/record/form/content/audit-trail/view";
import { Context } from "common/types/context";
import { hasPermissionToRead } from "common/functions/roles";
import { ApiError } from "common/ui/api-error";
import { GeneralPermissionsError } from "common/widgets/error";
import { LoadingIcon } from "common/widgets/loading-icon";

interface PropTypes {
  context: Context;
  entity: Entity;
  record: Record;
  auditTrailId: string;
  hideLinks?: boolean;
  getUrl?: (id: string | number) => string;
}

interface StateType {
  loading?: boolean;
  error?: any;
  events?: Event[];
  selected?: Event;
  offset?: number;
  isLastPage?: boolean;
}

export class AuditTrailController extends Component<PropTypes, StateType> {
  static readonly displayName = "AuditTrailController";

  static getDerivedStateFromProps(newProps: PropTypes, prevState: StateType) {
    const { selected, events = [] } = prevState;
    const prevId = selected?.id;
    if (prevId !== newProps.auditTrailId) {
      const newSelected = R.find((e) => e.id === newProps.auditTrailId, events);
      if (newSelected) {
        return merge1("selected", newSelected, prevState);
      }
    }
    return null;
  }

  state: StateType = {
    loading: true,
    offset: 0,
  };

  componentDidMount() {
    this.loadData(this.state.offset);
  }

  loadData = (offset: number) => {
    const { context, entity, record, auditTrailId } = this.props;
    const { events = [] } = this.state;

    this.setState({ loading: true, offset });
    eventsApi(context.apiCall)
      .get(entity.name, record.properties.id, offset)
      .then((newEvents: Event[] = []) => {
        const allEvents = events.concat(newEvents);
        this.setState({
          loading: false,
          events: allEvents,
          selected: auditTrailId
            ? R.find((e) => e.id === auditTrailId, allEvents)
            : undefined,
          isLastPage: newEvents.length < 10,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  };

  onShowMore = () => {
    this.loadData(this.state.offset + 10);
  };

  render() {
    const { entity, context, record, getUrl, hideLinks } = this.props;
    const { entities } = context;
    const { loading, error, events = [], selected, isLastPage } = this.state;

    if (!hasPermissionToRead(context.userTypes, context.role, "AuditTrail"))
      return <GeneralPermissionsError />;

    const filteredEvents = events.filter(
      (e) => !!e.details && !!entities[e.details.entityName],
    );
    const selectedEvent = selected || filteredEvents[0];

    return (
      <div>
        {error && <ApiError error={error} />}
        {filteredEvents.length ? (
          <AuditTrail
            entity={entity}
            context={context}
            events={filteredEvents}
            loading={loading}
            onShowMore={this.onShowMore}
            isLastPage={isLastPage}
            recordId={record.properties.id}
            selectedEvent={selectedEvent}
            getUrl={getUrl}
            hideLinks={hideLinks}
          />
        ) : loading ? (
          <LoadingIcon />
        ) : (
          <div className="x-no-events">{_("No events")}</div>
        )}
      </div>
    );
  }
}
