import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { RecordActions } from "common/record/actions";
import { Reload } from "common/record/types";
import { Context } from "common/types/context";
import { Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { RecordPopOut } from "common/widgets/record-pop-out";
import { Star } from "common/widgets/star";

interface PropTypesBase {
  context: Context;
  goTo: GoFn;
  reload: Reload;
  entity: Entity;
  record: Record;
}

export interface PropTypes extends PropTypesBase {
  toggleStar: () => void;
  starred: boolean;
  hidePanels: boolean;
}

export const Buttons = ({
  context,
  goTo,
  reload,
  entity,
  record,
  toggleStar,
  starred,
  hidePanels,
}: PropTypes) => {
  const recordId = record?.properties?.id;

  return record ? (
    <div className="x-btns-right">
      {recordId && (
        <>
          <Star recordId={recordId} starred={starred} toggleStar={toggleStar} />
          <RecordActions
            query={undefined}
            goTo={goTo}
            context={context}
            reload={reload}
            entity={entity}
            records={[record]}
            onTheRight={true}
            includeCopy={true}
            includeSwitchForm={
              filterFormsByEntity(context.forms, entity.name).length > 0
            }
            displayType="label"
          />
        </>
      )}
      {!hidePanels && (
        <RecordPopOut
          id={recordId || ""}
          entity={entity}
          site={context.site.name}
        />
      )}
    </div>
  ) : null;
};

Buttons.displayName = "Buttons";
