import { Component } from "react";
import { ValueProps } from "common/with-value-for";
import { MapValue, Pin, GetInfo } from "common/vendor-wrappers/leaflet/types";
import { LazyLeaflet } from "common/vendor-wrappers/leaflet";
import { LayerToggle } from "./layer-toggle";

export const defaultValue: MapValue = {
  pins: [],
  selected: undefined,
  imgSize: undefined,
};

interface Layer {
  show: boolean;
  count: number;
}

interface LayersInfo {
  [layer: string]: Layer;
}

interface StateType {
  hiddenLayers: string[];
}

const getLayers = (pins: Pin[] = [], hiddenLayers: string[]): LayersInfo =>
  pins.reduce<LayersInfo>(
    (acc, { layer }) => ({
      ...acc,
      [layer]: {
        ...acc[layer],
        show: !hiddenLayers.includes(layer),
        count: (acc[layer]?.count || 0) + 1,
      },
    }),
    {},
  );

interface Props extends ValueProps<MapValue> {
  imageUrl: string;
  getInfo: GetInfo;
  isReadOnly: boolean;
}

export class MapWithLayers extends Component<Props, StateType> {
  static readonly displayName = "MapWithLayers";

  state: StateType = { hiddenLayers: [] };

  onChangeMap = (newMap: MapValue = defaultValue) => {
    const { value = defaultValue, onChange } = this.props;
    const { pins: allPins = [] } = value;
    const { hiddenLayers } = this.state;

    if (hiddenLayers.length) {
      const pinsNotShown = allPins.filter((p) =>
        hiddenLayers.includes(p.layer),
      );
      onChange({ ...newMap, pins: newMap.pins.concat(pinsNotShown) });
    } else {
      onChange(newMap);
    }
  };

  toggleLayer = (layer: string, show: boolean) => {
    this.setState(({ hiddenLayers }) => ({
      hiddenLayers: show
        ? hiddenLayers.filter((l) => l !== layer)
        : hiddenLayers.concat(layer),
    }));
  };

  render() {
    const { value = defaultValue, onChange } = this.props;
    const { pins = [] } = value;
    const { hiddenLayers } = this.state;

    const layersInfo = getLayers(pins, hiddenLayers);
    const isSingleLayer = Object.keys(layersInfo).length < 2;

    const newValue = hiddenLayers.length
      ? {
          ...value,
          pins: pins.filter((p) => layersInfo[p.layer].show),
        }
      : value;

    return isSingleLayer ? (
      <div className="x-with-layers-container">
        <LazyLeaflet data-qa="leaflet" {...this.props} onChange={onChange} />
      </div>
    ) : (
      <div className="x-with-layers-container x-flex-start-start">
        <LazyLeaflet
          data-qa="leaflet-multilayers"
          {...this.props}
          value={newValue}
          onChange={this.onChangeMap}
        />
        <ul className="x-with-layers-buttons list-unstyled">
          {Object.entries(layersInfo).map(([layerName, { count, show }]) => (
            <li key={layerName} className="x-margin-bottom-5">
              <LayerToggle
                layerName={layerName}
                active={show}
                count={count}
                toggle={this.toggleLayer}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
