import * as R from "ramda";
import { Component } from "react";
import { CancellablePromise } from "common/types/promises";
import { defaultFor } from "common";
import { schedulerApi } from "common/api/scheduler";
import type { UTCDateTime } from "common/date-time/types";
import type { Entity } from "common/entities/types";
import { isAssignmentEvent, isRelatedEvent } from "common/functions/scheduler";
import type { Context } from "common/types/context";
import type { ApiErrorResponse } from "common/types/error";
import type { Form } from "common/types/forms";
import type { Record } from "common/types/records";
import type { SchedulerSettingsType } from "common/types/scheduler-settings";
import { ApiError } from "common/ui/api-error";
import { arrayToString } from "common/utils/array";
import { trackEvent } from "common/utils/mixpanel";
import { AlertWarning } from "common/widgets/alert";
import { Backdrop } from "common/widgets/backdrop";
import { LoadingIcon } from "common/widgets/loading-icon";
import { PersonalEventView } from "x/scheduler2/personal-event-view";
import { getPersonalEventEntity } from "x/scheduler2/personal-event-view/functions";
import { eventActions } from "x/scheduler2/planner/actions";
import { eventRenderer } from "x/scheduler2/planner/events";
import { SchedulerHeader } from "x/scheduler2/planner/header";
import { filterValueToId } from "x/scheduler2/planner/header/filter/filter-value";
import { plannerPresets } from "x/scheduler2/planner/presets";
import {
  getResourceCls,
  getResourceMeta,
  getResources,
  getUpdatedResourceEvents,
} from "x/scheduler2/planner/resources";
import { UnassignedWorkPanel } from "x/scheduler2/planner/unassigned-work";
import {
  getEventDuration,
  getEventWithDuration,
} from "x/scheduler2/planner/unassigned-work/functions/requirements";
import {
  getSchedulerData,
  setSchedulerPreferences,
} from "x/scheduler2/preferences";
import { SchedulerRibbon } from "x/scheduler2/ribbon";
import { getEventNumber } from "x/scheduler2/ribbon/crumbs";
import {
  getWorkingDateRange,
  translateViewForPreferences,
} from "x/scheduler2/shared";
import { getResourcesEvents } from "x/scheduler2/shared/events";
import { getResourceTimeRanges } from "x/scheduler2/shared/time-ranges";
import type {
  DropEvent,
  MouseEvent,
  EventModel,
  Resource,
  ResourceTimeRange,
  SchedulerData,
  SelectedWorkOrder,
  UserContact,
  ViewType,
} from "x/scheduler2/types";
import { WorkOrderView } from "x/scheduler2/workorder-view";
import { LazyBryntumScheduler } from "x/scheduler2/wrappers/lazy-scheduler";
import {
  getAssignmentsWithConflicts,
  getWorkOrderEntities,
  isUnassignedPanelVisible,
} from "./unassigned-work/functions";
import type { WorkOrder, WorkOrderEvent } from "./unassigned-work/types";

interface PropTypes {
  context: Context;
  userContact: UserContact;
  schedulerSettings: SchedulerSettingsType;
  assignmentEntities: Entity[];
  hidePanels?: boolean;
}

interface StateType {
  resources: Resource[];
  resourceTimeRanges: ResourceTimeRange[];
  events: EventModel[];
  value?: SchedulerData;
  newPersonalEventFormId: number;
  selectedPersonalEvent: EventModel;
  selectedWorkOrder: SelectedWorkOrder;
  assignedWorkOrderId: string;
  lastRefresh: number;
  isLoading: boolean;
  error: ApiErrorResponse;
}

export class PlannerArea extends Component<PropTypes, StateType> {
  personalEventViewRef: PersonalEventView = undefined;
  workOrderViewRef: WorkOrderView = undefined;
  fetchRecordsRequest: CancellablePromise<unknown>;

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      resources: [],
      resourceTimeRanges: [],
      events: [],
      value: getSchedulerData(props.context),
      newPersonalEventFormId: undefined,
      selectedPersonalEvent: undefined,
      selectedWorkOrder: undefined,
      assignedWorkOrderId: undefined,
      lastRefresh: undefined,
      isLoading: false,
      error: undefined,
    };
  }

  componentDidMount() {
    this.getResourcesAndEvents();
  }

  componentDidUpdate(prevProps: PropTypes, prevState: StateType) {
    if (
      prevProps.context.site.name !== this.props.context.site.name ||
      prevState.value !== this.state.value
    ) {
      this.getResourcesAndEvents();
    }
  }

  componentWillUnmount() {
    this.fetchRecordsRequest?.cancel();
  }

  getResourcesAndEvents = () => {
    const { context, schedulerSettings } = this.props;
    const { value } = this.state;
    const { view, filterValue } = value;
    const { apiCall } = context;

    this.setState({ isLoading: true });

    const { from, to } = getWorkingDateRange(value);

    this.fetchRecordsRequest = schedulerApi(apiCall)
      .getContactEvents(
        from,
        to,
        translateViewForPreferences(view),
        true,
        filterValueToId(filterValue),
      )
      .then((contactEvents = []) => {
        const resources = getResources(
          context,
          contactEvents,
          schedulerSettings?.groupBy,
        );
        const resourceTimeRanges = getResourceTimeRanges(contactEvents);
        const events = getResourcesEvents(context, contactEvents);

        this.setState({
          resources,
          resourceTimeRanges,
          events,
          isLoading: false,
          error: undefined,
        });
      })
      .catch((error) =>
        this.setState({
          resources: [],
          resourceTimeRanges: [],
          events: [],
          isLoading: false,
          error,
        }),
      );
  };

  onError = (error: ApiErrorResponse) => {
    this.setState({ error });
  };

  onReload = () => {
    this.setState({ lastRefresh: Date.now() });
    this.getResourcesAndEvents();
    if (this.personalEventViewRef) this.personalEventViewRef.reload();
  };

  onPresetChange = (view: ViewType) => {
    this.onChange({ ...this.state.value, view });
  };

  onDrillDown = (selectedDate: UTCDateTime, view: ViewType) => {
    this.onChange({ ...this.state.value, selectedDate, view });
  };

  onChange = (newValue: SchedulerData) => {
    const { context } = this.props;
    const { value } = this.state;

    if (R.equals(value, newValue)) return;

    this.setState({ value: newValue });
    setSchedulerPreferences(context, newValue);
  };

  onNewPersonalEvent = (form: Form) => () => {
    trackEvent("New Personal Event");
    this.setState({
      newPersonalEventFormId: form?.id,
      selectedPersonalEvent: defaultFor(),
    });
  };

  onCloseSelection = () => {
    this.setState({
      selectedPersonalEvent: undefined,
      selectedWorkOrder: undefined,
    });
  };

  onEventClick = (e: MouseEvent) => {
    const eventData = e.eventRecord.data.eventData;

    if (isRelatedEvent(eventData)) {
      this.setState({
        selectedPersonalEvent: e.eventRecord.data,
        selectedWorkOrder: undefined,
      });
    }

    if (isAssignmentEvent(eventData)) {
      const { workOrderId, site } = eventData;
      this.setSelectedWorkOrder(site, workOrderId.entity, workOrderId.id);
    }
  };

  setPersonalEventViewRef = (ref: PersonalEventView) => {
    this.personalEventViewRef = ref;
  };

  setWorkOrderViewRef = (ref: WorkOrderView) => {
    this.workOrderViewRef = ref;
  };

  onPreviewLoaded = (workOrder: Record) => {
    const { selectedWorkOrder } = this.state;
    if (selectedWorkOrder) {
      this.setState({
        selectedWorkOrder: { ...selectedWorkOrder, record: workOrder },
      });
    }
  };

  setSelectedWorkOrder = (
    site: string,
    entityName: string,
    recordId: string,
  ) => {
    const { events } = this.state;
    const conflictedEvents = getAssignmentsWithConflicts(events, recordId);
    this.setState({
      selectedWorkOrder: {
        site,
        entityName,
        id: recordId,
        assignmentsWithConflicts: conflictedEvents,
      },
      selectedPersonalEvent: undefined,
    });
  };

  onUnassignedClicked = (workOrder: WorkOrder) => {
    const { site, SYSTEM_entityName, SYSTEM_id } = workOrder;
    return () => this.setSelectedWorkOrder(site, SYSTEM_entityName, SYSTEM_id);
  };

  getContextFor = (withSite: { site?: string }) => {
    const { context } = this.props;
    return context.createContextForSiteIfExists(withSite?.site);
  };

  handleError = (error: ApiErrorResponse) => {
    this.setState({ error, isLoading: false });
  };

  updateResources = (resourceIds: Array<string | number>) => {
    const { context } = this.props;
    const { value, resources, events } = this.state;

    return getUpdatedResourceEvents(context, value, resourceIds).then(
      ({ resourceUpdate, newEvents }) => {
        const newResources = resources.map((resource) => {
          const newResource = resourceUpdate[resource.id]
            ? { ...resource, ...resourceUpdate[resource.id] }
            : resource;

          return {
            ...newResource,
            cls: getResourceCls(newResource),
          };
        });

        const updatedEvents = events
          .filter((event) => !resourceUpdate[event.resourceId])
          .concat(newEvents);

        this.setState({ resources: newResources, events: updatedEvents });
      },
    );
  };

  storeAssignedWorkOrderId = (event: WorkOrderEvent) => {
    const assignedWorkOrderId = event?.workOrderId;

    this.setState({ assignedWorkOrderId });
  };

  onEventReceived = (event: WorkOrderEvent): CancellablePromise<any> => {
    const { assignmentEntities, schedulerSettings } = this.props;
    const { resources } = this.state;

    this.setState({ isLoading: true });

    return getEventDuration(
      this.getContextFor(event),
      resources,
      schedulerSettings,
      event,
    )
      .then((duration) =>
        eventActions(this.getContextFor(event), assignmentEntities).create(
          getEventWithDuration(event, duration),
        ),
      )
      .then(() => this.storeAssignedWorkOrderId(event))
      .then(() => this.updateResources([event.resourceId]))
      .then(() => this.setState({ isLoading: false }))
      .catch(this.handleError);
  };

  onEventReassign = (reassignedEvent: DropEvent) => {
    const { assignmentEntities } = this.props;
    const event = reassignedEvent.eventRecords[0];

    this.setState({ isLoading: true });

    return eventActions(
      this.getContextFor(event.data.eventData),
      assignmentEntities,
    )
      .update(event.data)
      .then(() =>
        this.updateResources([
          event.data.resourceId,
          event.originalData.resourceId,
        ]),
      )
      .then(() => this.setState({ isLoading: false }))
      .catch(this.handleError);
  };

  onEventResize = (mouseEvent: MouseEvent): CancellablePromise<any> => {
    const { assignmentEntities } = this.props;
    const event = mouseEvent.eventRecord.data;

    if (!mouseEvent.changed) return undefined;

    this.setState({ isLoading: true });

    return eventActions(
      this.getContextFor(event?.eventData),
      assignmentEntities,
    )
      .update(event)
      .then(() => this.updateResources([event.resourceId]))
      .then(() => this.setState({ isLoading: false }))
      .catch(this.handleError);
  };

  render() {
    const {
      context,
      userContact,
      schedulerSettings,
      assignmentEntities,
      hidePanels,
    } = this.props;
    const {
      resources,
      resourceTimeRanges,
      events,
      value,
      selectedPersonalEvent,
      newPersonalEventFormId,
      selectedWorkOrder,
      assignedWorkOrderId,
      lastRefresh,
      isLoading,
      error,
    } = this.state;
    const { view, selectedDate, hideWeekends } = value;

    const isPanelOpened = !!(selectedWorkOrder || selectedPersonalEvent);
    const isDraggable =
      !context.site.isGroup && !!resources?.length && !isLoading;

    const { from, to } = getWorkingDateRange(value);

    const userEntityName = context.entities[schedulerSettings?.entityName].name;
    const personalEventEntity = getPersonalEventEntity(
      context.entities,
      userEntityName,
      undefined,
    );
    const containerClasses = arrayToString([
      "x-planner-area",
      `x-planner-area-${view}-view`,
    ]);

    return (
      <>
        <SchedulerRibbon
          eventNumber={getEventNumber(
            selectedPersonalEvent || selectedWorkOrder,
          )}
          personalEventEntity={personalEventEntity}
          context={context}
          hidePanels={hidePanels}
          readOnly={!userContact}
          reload={this.onReload}
          onPreviewClose={this.onCloseSelection}
          onCreateEvent={this.onNewPersonalEvent}
        />

        <div className={containerClasses}>
          {isLoading ? <LoadingIcon /> : undefined}
          {error ? (
            <ApiError className="x-scheduler-alert" error={error} />
          ) : undefined}
          {error && <Backdrop className="x-scheduler-backdrop" />}

          <div className="x-scheduler-container">
            <SchedulerHeader
              context={context}
              assignmentsEntities={assignmentEntities}
              schedulerSettings={schedulerSettings}
              unpublishedEvents={[]}
              reload={this.onReload}
              onError={this.onError}
              value={value}
              onChange={this.onChange}
            />

            {/*TODO Remove after the monthly view is implemented*/}
            {view === "month" ? (
              <AlertWarning message={"Monthly view is not implemented yet"} />
            ) : (
              <LazyBryntumScheduler
                events={events}
                from={from}
                to={to}
                isDraggable={isDraggable}
                view={view}
                presets={plannerPresets}
                resources={resources}
                resourceTimeRanges={resourceTimeRanges}
                schedulerSettings={schedulerSettings}
                getResourceMeta={getResourceMeta}
                onEventClick={this.onEventClick}
                onEventResize={this.onEventResize}
                onPresetChange={this.onPresetChange}
                onDrillDown={this.onDrillDown}
                onEventReassign={this.onEventReassign}
                onEventReceive={this.onEventReceived}
                eventRenderer={eventRenderer}
              />
            )}
          </div>

          {selectedWorkOrder && (
            <>
              <WorkOrderView
                isPlanner={true}
                ref={this.setWorkOrderViewRef}
                selectedWorkOrder={selectedWorkOrder}
                context={context.createContextForSiteIfExists(
                  selectedWorkOrder?.site,
                )}
                reload={this.onReload}
                onClose={this.onCloseSelection}
                onLoaded={this.onPreviewLoaded}
              />
              <Backdrop onClick={this.onCloseSelection} />
            </>
          )}

          {selectedPersonalEvent && (
            <>
              <PersonalEventView
                ref={this.setPersonalEventViewRef}
                context={context}
                personalEventEntity={personalEventEntity}
                event={selectedPersonalEvent}
                userContact={userContact}
                formId={newPersonalEventFormId}
                reload={this.onReload}
                onClose={this.onCloseSelection}
              />
              <Backdrop onClick={this.onCloseSelection} />
            </>
          )}
          {isUnassignedPanelVisible(view) ? (
            <UnassignedWorkPanel
              workOrderEntities={getWorkOrderEntities(
                context.entities,
                assignmentEntities,
              )}
              context={context}
              isDraggable={isDraggable}
              view={view}
              selectedDate={selectedDate}
              onWorkOrderViewClick={this.onUnassignedClicked}
              hideWeekends={hideWeekends}
              assignedWorkOrderId={assignedWorkOrderId}
              isVisible={!isPanelOpened}
              lastRefresh={lastRefresh}
            />
          ) : undefined}
        </div>
      </>
    );
  }
}
