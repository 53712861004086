interface PropTypes {
  isDefault: boolean;
  disableEdit: boolean;
  setAsDefault: () => void;
}

export const DefaultImage = ({
  isDefault,
  disableEdit,
  setAsDefault,
}: PropTypes) => {
  const shouldRenderButton = setAsDefault && !disableEdit;
  return (
    <>
      {isDefault ? (
        <em className="qa-is-default x-margin-left-10">{_("default image")}</em>
      ) : shouldRenderButton ? (
        <button
          className="qa-set-default x-button-workflows-small x-margin-left-10"
          onClick={setAsDefault}
        >
          {_("Set as default image")}
        </button>
      ) : undefined}
    </>
  );
};
